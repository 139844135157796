import {BuildOutlined, HomeOutlined, PlusOutlined, ReconciliationOutlined, RightOutlined} from '@ant-design/icons';
import {Button, Drawer, Dropdown, Flex, Menu, MenuProps, Spin, Typography} from 'antd';
import {FC, useEffect, useState} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import useWindowSize from 'src/hooks/useWindowSize';
import {ROUTE_CONFIG} from 'src/routes/config';
import BlockService from 'src/services/block';
import {IBlockInfo} from 'src/services/block/type';
import BuildingService from 'src/services/building';
import {IBuildingInfo} from 'src/services/building/type';
import ProjectService from 'src/services/project';
import {IProjectInfo} from 'src/services/project/type';
import {closeMobileMenu} from 'src/stores/common/layout.slice';
import {useAppDispatch, useAppSelector} from 'src/stores/utils';
import {checkIsAdmin, checkIsSuperAdmin} from 'src/utils/common/function';
import {showNotiErr} from 'src/utils/notification';
//
interface IBlockMenu extends IBlockInfo {
  listBuilding?: IBuildingInfo[];
}
interface IProjectMenu extends IProjectInfo {
  listBlock?: IBlockMenu[];
}
const {Text} = Typography;
const projectService = new ProjectService();
const buildingService = new BuildingService();
const blockService = new BlockService();
//
const MobileMenu: FC = () => {
  const dispatch = useAppDispatch();
  const layoutState = useAppSelector(state => state.layout);
  const {reloadListProject, showMobileMenu} = layoutState;
  //
  const {windowSize} = useWindowSize();
  const location = useLocation();
  const navigate = useNavigate();
  const [openKeys, setOpenKeys] = useState(['/']);
  const [selectedKey, setSelectedKey] = useState('/');
  const [parentMenuKey, setParentMenuKey] = useState<String[]>([]);
  const [loadingProject, setLoadingProject] = useState<boolean>(false);
  const [listProject, setListProject] = useState<IProjectMenu[]>([]);
  const [listBlockMenu, setListBlockMenu] = useState<IBlockMenu[]>([]);
  //

  useEffect(() => {
    getListProjects();
  }, [reloadListProject]);
  //
  const getListProjects = async () => {
    setLoadingProject(true);

    projectService[checkIsAdmin() ? 'listByMyAdmin' : 'listProject']({
      page: 1,
      results: 50,
      sortField: 'orderBy',
      sortOrder: 'ascend',
    })
      .then(res => {
        const {data} = res;
        const {list} = data;
        let newProjectList: IProjectMenu[] = [...list];
        let listBlockWithoutProject: IBlockMenu[] = [];
        // Lấy tiếp list Phân khu
        blockService
          .listBlock()
          .then(res => {
            const {data} = res;
            const {list: blockList} = data;
            blockList.forEach(item => {
              if (!Boolean(item.projectId)) {
                listBlockWithoutProject.push(item);
              }
            });
            newProjectList.forEach(projectItem => {
              const {_id} = projectItem;
              let listBlockInProject = blockList.filter(item => item.projectId === _id);
              projectItem.listBlock = listBlockInProject;
            });

            buildingService
              .listBuilding({
                page: 1,
                results: 50,
              })
              .then(res => {
                const {data} = res;
                const {list: buildingList} = data;
                newProjectList.forEach(projectItem => {
                  const {_id, listBlock} = projectItem;

                  let listBuildingInProject = buildingList.filter(building => building.projectId === _id);
                  listBlock?.forEach(block => {
                    block.listBuilding = listBuildingInProject.filter(building => building.blockId === block._id);
                  });
                });
                listBlockWithoutProject.forEach(block => {
                  block.listBuilding = buildingList.filter(building => building.blockId === block._id);
                });
              })
              .catch(err => showNotiErr(err))
              .finally(() => {
                setListProject(newProjectList);
                setListBlockMenu(listBlockWithoutProject);
              });
          })
          .catch(err => showNotiErr(err));
      })
      .catch(err => showNotiErr(err))
      .finally(() => {
        setLoadingProject(false);
      });
  };

  //

  //
  // useEffect(() => {
  //   if (location.pathname !== '/') {
  //     let _submenu: string[] = [];
  //     let _string = '';
  //     _submenu = split(location.pathname, '/').filter(url => {
  //       if (url !== '' && !LIST_ACTION_IGNORE_ACTIVE_MENU.includes(url) && !Number(url)) {
  //         return url;
  //       }
  //     });
  //     _submenu.map(item => {
  //       _string = _string + '/' + item;
  //     });
  //     setOpenKeys(['/' + _submenu[0]]);
  //     setSelectedKey(_string);
  //   } else {
  //     setOpenKeys(['/']);
  //     setSelectedKey('/');
  //   }
  //   let route: string[] = [];
  //   if (routes !== undefined && routes.length > 0) {
  //     for (let index = 0; index < routes.length; index++) {
  //       route.push(routes[index]?.key?.toString()!);
  //     }
  //   }
  //   setParentMenuKey(route);
  // }, [location.pathname, routes]);

  const onOpenChange: MenuProps['onOpenChange'] = keys => {
    const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
    if (parentMenuKey.indexOf(latestOpenKey!) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const onClick: MenuProps['onClick'] = e => {
    navigate(e.key);
    setSelectedKey(e.key);
    dispatch(closeMobileMenu());
  };

  return (
    <Drawer
      loading={loadingProject}
      placement="left"
      destroyOnClose
      open={showMobileMenu}
      width={windowSize.width * 0.65}
      onClose={() => dispatch(closeMobileMenu())}
      title="Đông Đô Land Menu">
      <Menu
        className="sider-content"
        theme="light"
        mode="vertical"
        openKeys={openKeys}
        selectedKeys={[selectedKey]}
        onClick={onClick}
        onOpenChange={onOpenChange}>
        <Menu.Item key={ROUTE_CONFIG.HOME} icon={<HomeOutlined />}>
          Trang chủ
        </Menu.Item>

        <Menu.Divider />
        <Menu.ItemGroup
          title={
            <Flex align="center" justify="space-between">
              <Text>Danh sách dự án</Text>
              {checkIsSuperAdmin() && (
                <Dropdown
                  trigger={['click']}
                  menu={{
                    items: [
                      {
                        key: '1',
                        label: <Link to={ROUTE_CONFIG.ADD_PROJECT}>Thêm dự án</Link>,
                        icon: <ReconciliationOutlined />,
                      },
                      {
                        key: '2',
                        label: <Link to={`${ROUTE_CONFIG.ADD_BLOCK}/null`}>Thêm phân khu</Link>,
                        icon: <BuildOutlined />,
                      },
                    ],
                  }}>
                  <Button type="text" shape="circle" icon={<PlusOutlined />} />
                </Dropdown>
              )}
            </Flex>
          }>
          {loadingProject ? (
            <Menu.Item disabled>
              <Spin size="small" />
            </Menu.Item>
          ) : (
            listProject.map((item, index) => {
              const {isPrivate} = item;
              const IS_PRIVATE_AND_HAVE_PERMISSION = isPrivate && (checkIsAdmin() || checkIsSuperAdmin());
              const NOT_PRIVATE = !isPrivate;
              if (IS_PRIVATE_AND_HAVE_PERMISSION || NOT_PRIVATE) {
                return (
                  <Menu.SubMenu
                    onTitleClick={() => {
                      navigate(`${ROUTE_CONFIG.PROJECT_INFO}/${item._id}`);
                    }}
                    key={`${ROUTE_CONFIG.PROJECT_INFO}/${item._id}`}
                    icon={<ReconciliationOutlined style={{fontSize: 12}} />}
                    style={{opacity: isPrivate ? 0.5 : 1}}
                    title={`Dự án ${item.shortName}`}>
                    {item.listBlock && item.listBlock.length > 0 ? (
                      item.listBlock.map(block => (
                        <Menu.SubMenu
                          onTitleClick={() => {
                            navigate(`${ROUTE_CONFIG.BLOCK_INFO}/${block._id}`);
                          }}
                          key={`${ROUTE_CONFIG.BLOCK_INFO}/${block._id}`}
                          icon={<RightOutlined style={{fontSize: 10}} />}
                          title={block.name}>
                          {block.listBuilding &&
                            block.listBuilding.map(building => (
                              <Menu.Item
                                key={`${ROUTE_CONFIG.BUILDING_INFO}/${building._id}`}
                                icon={<RightOutlined style={{fontSize: 10}} />}>
                                Toà {building.name}
                              </Menu.Item>
                            ))}
                          {checkIsAdmin() || checkIsSuperAdmin() ? (
                            <Menu.Item
                              key={`${ROUTE_CONFIG.ADD_BUILDING}/${item._id}/${block._id}`}
                              icon={<PlusOutlined style={{fontSize: 10}} />}>
                              Thêm toà nhà
                            </Menu.Item>
                          ) : (
                            <></>
                          )}
                        </Menu.SubMenu>
                      ))
                    ) : (
                      <></>
                    )}
                    {checkIsAdmin() || checkIsSuperAdmin() ? (
                      <Menu.Item
                        key={`${ROUTE_CONFIG.ADD_BLOCK}/${item._id}`}
                        icon={<PlusOutlined style={{fontSize: 10}} />}>
                        Thêm phân khu
                      </Menu.Item>
                    ) : (
                      <></>
                    )}
                  </Menu.SubMenu>
                );
              } else {
                return <></>;
              }
            })
          )}
        </Menu.ItemGroup>
        {listBlockMenu.length > 0 ? (
          listBlockMenu.map(block => (
            <Menu.SubMenu
              level={1}
              key={`${ROUTE_CONFIG.BLOCK_INFO}/${block._id}`}
              icon={<BuildOutlined style={{fontSize: 12}} />}
              onTitleClick={() => {
                navigate(`${ROUTE_CONFIG.BLOCK_INFO}/${block._id}`);
              }}
              title={block.name}>
              {block.listBuilding &&
                block.listBuilding.map(building => (
                  <Menu.Item
                    key={`${ROUTE_CONFIG.BUILDING_INFO}/${building._id}`}
                    icon={<RightOutlined style={{fontSize: 10}} />}>
                    Toà {building.name}
                  </Menu.Item>
                ))}
              {checkIsAdmin() || checkIsSuperAdmin() ? (
                <Menu.Item
                  key={`${ROUTE_CONFIG.ADD_BUILDING}/null/${block._id}`}
                  icon={<PlusOutlined style={{fontSize: 10}} />}>
                  Thêm toà nhà
                </Menu.Item>
              ) : (
                <></>
              )}
            </Menu.SubMenu>
          ))
        ) : (
          <></>
        )}
      </Menu>
    </Drawer>
  );
};

export default MobileMenu;

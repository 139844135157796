export const AUTHEN_ENDPOINT = {
  signIn: '/auth/login',
  signInWithPhone: '/auth/loginWithPhone',
  signUp: '/auth/register',
  sendOTP: '/auth/sendOTPRequest',
  verifyOTP: '/auth/verifyOTPRequest',
  oauth: '/auth/oauth/google',
  refreshToken: '/auth/refreshToken',
  forgetPassword: '/auth/forgetPassword'
};

import {AppstoreOutlined, BarsOutlined, ReloadOutlined} from '@ant-design/icons';
import {Button, Flex, Segmented} from 'antd';
import {FC, Fragment, ReactNode, memo} from 'react';
import {EMultiViews} from 'src/utils/common/enum';

interface IMultiViewsProps {
  firstView: ReactNode;
  secondView: ReactNode;
  currentViewType: EMultiViews | string;
  onChangeView: (type: EMultiViews | string) => void;
  onReload?: () => void;
}
const MultiViewsSegmented: FC<IMultiViewsProps> = props => {
  const {firstView, secondView, currentViewType, onChangeView, onReload} = props;
  //
  return (
    <Fragment>
      <Flex justify="space-between" align="center" style={{marginBottom: 15}}>
        <Segmented
          style={{alignSelf: 'flex-start'}}
          onChange={onChangeView}
          value={currentViewType}
          options={[
            {value: EMultiViews.simple, icon: <BarsOutlined />},
            {value: EMultiViews.full, icon: <AppstoreOutlined />},
          ]}
        />
        <Button onClick={onReload} title="Tải lại" icon={<ReloadOutlined />} size="small" type="primary">
          Tải lại
        </Button>
      </Flex>

      <div>{currentViewType === EMultiViews.simple ? firstView : secondView}</div>
    </Fragment>
  );
};
export default memo(MultiViewsSegmented);

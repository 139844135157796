import {PayloadAction, createSlice} from '@reduxjs/toolkit';

import {IUserInfo} from 'src/services/authentication/type';

interface State {
  isLogin: boolean;
  user: IUserInfo | null;
  tokens: {
    accessToken: string;
    refreshToken: string;
  };
}

const initialState: State = {
  isLogin: false,
  user: null,
  tokens: {
    accessToken: '',
    refreshToken: '',
  },
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, {payload}: PayloadAction<IUserInfo | null>) => ({
      ...state,
      user: payload,
    }),
    setIsLogin: (state, {payload}: PayloadAction<boolean>) => ({
      ...state,
      isLogin: payload,
    }),
    setTokens: (state, {payload}: PayloadAction<{accessToken: string; refreshToken: string}>) => ({
      ...state,
      tokens: payload,
    }),
  },
});

export default authSlice;

export const ROUTE_CONFIG = {
  HOME: '/',
  // Đăng nhập
  SIGNIN: '/signIn',
  SIGNUP: '/signUp',
  OTP: '/otp',
  ENTER_MAIL: '/enter-mail',
  // Quản lý tài khoản
  PROFILE: '/profile',
  DECENTRALIZATION: '/decentralization',
  USER_EVENT: 'user-event',
  // Toà nhà
  ADD_BUILDING: '/add-building',
  BUILDING_INFO: '/building-info',
  UDPATE_BUILDING: '/update-building',
  // Dự án
  ADD_PROJECT: '/add-project',
  PROJECT_INFO: '/project-info',
  UDPATE_PROJECT: '/update-project',
  // Phân khu
  ADD_BLOCK: '/add-block/project',
  BLOCK_INFO: '/block-info',
  UPDATE_BLOCK: '/update-block',
  // PHỤ
  SEARCH: '/search',
};

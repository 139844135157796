import {ReloadOutlined} from '@ant-design/icons';
import {Button, Empty, Flex, Spin} from 'antd';
import {FC, Fragment, useEffect, useState} from 'react';
import ExcelTable from 'src/components/common/excelTable';
import {IBuildingInfo} from 'src/services/building/type';
import StockService from 'src/services/stock';
import {IHTMLInfo} from 'src/services/stock/type';
import {showNotiErr} from 'src/utils/notification';
//
interface IProps {
  detail: IBuildingInfo | null;
}
//
const stockService = new StockService();
const DUMMY_HTML_CONTENT = {
  _id: '',
  name: '',
  fileExtension: '',
  localPath: '',
  spreadsheetEditLink: '',
  spreadsheetId: '',
  downloadPath: '',
  remotePath: '',
  updatedAt: '',
  createdAt: '',
};
//
const UploadTrackingPlanForm: FC<IProps> = props => {
  const {detail} = props;
  //

  const [loading, setLoading] = useState<boolean>(false);
  const [existedHTMLcontent, setExistedHTMLContent] = useState<IHTMLInfo | null>(null);
  const [reload, setReload] = useState<boolean>(false);
  //
  useEffect(() => {
    getExcelTableContent();
  }, [detail, reload]);
  //

  const getExcelTableContent = async () => {
    if (detail && detail.fileId) {
      setLoading(true);
      // Get File excel
      stockService
        .renderHTMLJSON(detail.fileId)
        .then(htmlRes => {
          const {data} = htmlRes;
          const {render} = data;
          if (render) {
            setExistedHTMLContent(data);
          } else {
            setExistedHTMLContent({
              ...DUMMY_HTML_CONTENT,
              render: {
                fileId: detail.fileId || '',
                html: '',
                updatedAt: '',
              },
            });
          }
        })
        .catch(err => showNotiErr(err))
        .finally(() => setLoading(false));
    } else return;
  };
  //
  const handleReload = () => {
    setReload(prev => !prev);
  };
  //
  if (loading) {
    return <Spin fullscreen />;
  } else if (existedHTMLcontent && existedHTMLcontent.render) {
    return (
      <Fragment>
        <Flex align="center" gap={8}>
          <Button
            size="small"
            type="primary"
            onClick={() => window.open(existedHTMLcontent.spreadsheetEditLink, '_blank')}>
            Chỉnh sửa nội dung
          </Button>
          <Button onClick={handleReload} title="Tải lại" icon={<ReloadOutlined />} size="small" type="primary">
            Tải lại
          </Button>
        </Flex>

        <ExcelTable
          html={existedHTMLcontent.render.html}
          projectId={detail?.projectId || ''}
          buildingId={detail?._id || ''}
        />
      </Fragment>
    );
  } else return <Empty />;
};
export default UploadTrackingPlanForm;

import {Breadcrumb, Col, Flex, Image, Row, Spin, Tabs, TabsProps, Typography} from 'antd';
import {FC, useEffect, useMemo, useState} from 'react';
import AccountService from 'src/services/account';
import {useAppDispatch, useAppSelector} from 'src/stores/utils';
import {translateUserRole} from 'src/utils/common/function';
import {setUser} from '../authentication/store';
import ChangePassword from './components/changePassword';
import ProfileInformation from './components/profileInformation';
import './styles.scss';
//
const {Title, Text} = Typography;
//
enum EProfileTab {
  information = '1',
  changePassword = '2',
  decentralization = '3',
}
//
const accountService = new AccountService();
//
const ProfileScreen: FC = () => {
  const authState = useAppSelector(state => state.auth);
  const {user} = authState;
  const dispatch = useAppDispatch();
  //
  const tabItems: TabsProps['items'] = useMemo(
    () => [
      {
        key: EProfileTab.information,
        label: 'Thông tin tài khoản',
        children: <ProfileInformation />,
      },
      {
        key: EProfileTab.changePassword,
        label: 'Đổi mật khẩu',
        children: <ChangePassword />,
      },
    ],
    [user?.role],
  );
  //
  const [activeTab, setActiveTab] = useState<EProfileTab | string>(EProfileTab.information);
  const [loadingUser, setLoadingUser] = useState<boolean>(false);
  //
  useEffect(() => {
    getUserInfo();
  }, []);
  const getUserInfo = async () => {
    setLoadingUser(true);
    try {
      let res = await accountService.getInfo();
      if (res && res.code === 200) {
        const {data} = res;
        dispatch(setUser(data));
      }
    } catch (err) {}
    setLoadingUser(false);
  };

  const handleChangeTab = (nextTab: EProfileTab | string) => {
    if (typeof nextTab === typeof EProfileTab && activeTab !== nextTab) {
      setActiveTab(nextTab);
    } else return;
  };
  return (
    <div className="profile-screen-container">
      {loadingUser && <Spin fullscreen />}
      <Title level={3}>Hồ sơ cá nhân</Title>
      <Breadcrumb>
        <Breadcrumb.Item href="/">Trang chủ</Breadcrumb.Item>
        <Breadcrumb.Item>Hồ sơ cá nhân</Breadcrumb.Item>
      </Breadcrumb>
      <Row gutter={20} className="profile-content-container">
        <Col xxl={6} xl={6} lg={6} md={6} sm={24} xs={24} span={6}>
          <div className="section-container">
            <Flex vertical align="center">
              <Image width={150} src={require('src/assets/logo/logo.jpg')} />
              <Title level={3}>{user?.displayName}</Title>
              <Text>{translateUserRole()}</Text>
            </Flex>
          </div>
        </Col>
        <Col xxl={18} xl={18} lg={18} md={18} sm={24} xs={24} span={18}>
          <div className="section-container">
            <Tabs defaultActiveKey={activeTab} items={tabItems} onChange={handleChangeTab} />
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default ProfileScreen;

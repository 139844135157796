import { Breadcrumb, Button, Card, Descriptions, Empty, Flex, Popconfirm, Spin, notification } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import BuildingToolbar from 'src/components/common/buildingToolbar';
import ExcelTable from 'src/components/common/excelTable';
import HTMLParser from 'src/components/common/htmllParser';
import MultiViewsSegmented from 'src/components/common/multiViewsSegment';
import { ROUTE_CONFIG } from 'src/routes/config';
import ScreenParams from 'src/routes/screenParams';
import BuildingService from 'src/services/building';
import { IBuildingInfo } from 'src/services/building/type';
import ProfileService from 'src/services/profile';
import StockService from 'src/services/stock';
import { IStockInfo } from 'src/services/stock/type';
import { toggleReloadProject } from 'src/stores/common/layout.slice';
import { useAppDispatch, useAppSelector } from 'src/stores/utils';
import { EMultiViews, EUserRole } from 'src/utils/common/enum';
import { checkIsAdmin, checkIsSuperAdmin } from 'src/utils/common/function';
import { showNotiErr } from 'src/utils/notification';
import StockTable from './components/stockTable';
//
const buildingService = new BuildingService();
const stockService = new StockService();
const profileService = new ProfileService();
//
const BuildingInfo: FC = () => {
  const {id} = useParams<ScreenParams['BUILDING_INFO']>();
  const navigate = useNavigate();

  //
  const {user} = useAppSelector(state => state.auth);
  const dispatch = useAppDispatch();
  //
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingStockTable, setLoadingStockTable] = useState<boolean>(false);
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
  const [loadingPlan, setLoadingPlan] = useState<boolean>(false);
  const [currentViewType, setCurrentViewType] = useState<EMultiViews | string>(EMultiViews.full);
  const [buildingDetail, setBuildingDetail] = useState<IBuildingInfo | null>(null);
  const [stockData, setStockData] = useState<IStockInfo[]>([]);
  const [excelTable, setExcelTable] = useState<string>('');
  const [reload, setReload] = useState<boolean>(false);
  //
  useEffect(() => {
    getBuildingInfo();
  }, [id, reload]);
  useEffect(() => {
    getExcelTableContent();
  }, [buildingDetail, currentViewType, reload]);
  //
  const getBuildingInfo = async () => {
    if (id) {
      setLoading(true);
      buildingService
        .getBuildingDetail(id)
        .then(res => {
          const {data} = res;
          const {worksheetStockNames} = data;
          setBuildingDetail(data);
          // Lấy danh sách toà nhà
          setLoadingStockTable(true);
          stockService
            .listStock({
              buildingId: data._id,
              projectId: data.projectId,
              page: 1,
              results: 50,
              sheetName:
                worksheetStockNames && worksheetStockNames.length > 0 ? data.worksheetStockNames[0] : undefined,
            })
            .then(res => {
              const {data} = res;
              const {list} = data;
              const isNotAdmin = !checkIsAdmin() && !checkIsSuperAdmin();
              if (isNotAdmin) {
                if (user) {
                  const currentRole = user.role;
                  if (currentRole) {
                    const canReadStockList = list.filter(item =>
                      item.canRead && item.canRead.length > 0 ? item.canRead.includes(currentRole) : true,
                    );

                    setStockData(canReadStockList);
                  }
                } else {
                  const canReadStockList = list.filter(item =>
                    item.canRead && item.canRead.length > 0 ? item.canRead.includes(EUserRole.GUEST) : true,
                  );

                  setStockData(canReadStockList);
                }
              } else {
                setStockData(list);
              }
            })
            .catch(err => showNotiErr(err))
            .finally(() => setLoadingStockTable(false));
        })
        .catch(err => showNotiErr(err))
        .finally(() => setLoading(false));
    }
  };
  const getExcelTableContent = async () => {
    if (buildingDetail && buildingDetail.fileId && currentViewType === EMultiViews.full) {
      // Get File excel
      stockService
        .renderHTMLById(buildingDetail.fileId)
        .then(htmlRes => (typeof htmlRes === 'string' ? setExcelTable(htmlRes) : null))
        .catch(err => showNotiErr(err));
    } else return;
  };
  //
  const handleNavigateUpdate = () => {
    navigate(`${ROUTE_CONFIG.UDPATE_BUILDING}/${id}`);
  };
  const handleChangeViewType = (type: EMultiViews | string) => {
    if (type !== currentViewType) {
      setCurrentViewType(type);
    }
  };
  //
  const handleReload = () => {
    setReload(prev => !prev);
  };
  const reUpdateExcelTable = async () => {
    if (buildingDetail && buildingDetail.fileId) {
      // Get File excel
      setLoadingPlan(true);
      profileService
        .reUpdateHTMLContent(buildingDetail.fileId)
        .then(htmlRes => {
          const {data} = htmlRes;
          const {html} = data;

          setExcelTable(html);
        })
        .catch(err => showNotiErr(err))
        .finally(() => {
          setLoadingPlan(false);
        });
    } else {
      return;
    }
  };
  //
  const handleDeleteBuilding = async () => {
    setLoadingDelete(true);
    buildingService
      .deleteBuilding(buildingDetail?._id || '')
      .then(res => {
        const {message} = res;
        navigate(ROUTE_CONFIG.HOME);
        notification.success({message});
        dispatch(toggleReloadProject());
      })
      .then(err => showNotiErr(err))
      .finally(() => {
        setLoadingDelete(false);
      });
  };
  //
  return (
    <Flex vertical gap={15}>
      <Flex justify="space-between" align="flex-end" wrap="wrap">
        <Breadcrumb>
          <Breadcrumb.Item href="/">Trang chủ</Breadcrumb.Item>
          {buildingDetail && buildingDetail.project && (
            <Breadcrumb.Item href={`${ROUTE_CONFIG.PROJECT_INFO}/${buildingDetail.projectId}`}>
              {buildingDetail.project.name}
            </Breadcrumb.Item>
          )}
          {buildingDetail && buildingDetail.block && (
            <Breadcrumb.Item href={`${ROUTE_CONFIG.BLOCK_INFO}/${buildingDetail.blockId}`}>
              {buildingDetail.block.name}
            </Breadcrumb.Item>
          )}
          <Breadcrumb.Item>{buildingDetail?.name}</Breadcrumb.Item>
        </Breadcrumb>
        <Flex align="flex-end" wrap="wrap" gap={8}>
          {checkIsAdmin() || checkIsSuperAdmin() ? (
            <Button onClick={handleNavigateUpdate} type="primary">
              Chỉnh sửa thông tin
            </Button>
          ) : (
            <></>
          )}
          {checkIsSuperAdmin() ? (
            <Popconfirm
              title="Xoá toà nhà"
              description="Bạn chắc chắn muốn xoá toà nhà này?"
              onConfirm={handleDeleteBuilding}
              okButtonProps={{loading: loadingDelete}}>
              <Button loading={loadingDelete} danger type="dashed">
                Xoá toà nhà
              </Button>
            </Popconfirm>
          ) : (
            <></>
          )}
        </Flex>
      </Flex>
      <Card>
        <Flex vertical gap={15}>
          {buildingDetail && buildingDetail.overviews ? (
            <Descriptions size="middle">
              {buildingDetail.overviews.map((item, index) => (
                <Descriptions.Item label={item.title}>{item.value}</Descriptions.Item>
              ))}
            </Descriptions>
          ) : (
            <></>
          )}
          <MultiViewsSegmented
            currentViewType={currentViewType}
            onChangeView={handleChangeViewType}
            firstView={<StockTable dataTable={stockData} loading={loadingStockTable} detail={buildingDetail} />}
            onReload={currentViewType === EMultiViews.simple ? handleReload : reUpdateExcelTable}
            secondView={
              excelTable ? (
                <ExcelTable
                  html={excelTable}
                  projectId={buildingDetail?.projectId || ''}
                  buildingId={buildingDetail?._id || ''}
                  loading={loadingPlan}
                />
              ) : (
                <Empty />
              )
            }
          />

          <BuildingToolbar buildingDetail={buildingDetail} />
          {buildingDetail && buildingDetail.links ? (
            <Flex align="center" gap={10}>
              {buildingDetail.links.map((item, index) => (
                <Link to={item.value} key={index} target="_blank">
                  - {item.title}
                </Link>
              ))}
            </Flex>
          ) : (
            <></>
          )}
          <Card style={{backgroundColor: 'rgba(67, 89, 113, 0.05)'}}>
            <HTMLParser content={buildingDetail?.content} />
          </Card>
        </Flex>
      </Card>
      {loading && <Spin size="large" />}
    </Flex>
  );
};
export default BuildingInfo;

import {Button, Form, Input, Modal, Select, Typography, notification} from 'antd';
import {FC, useState} from 'react';
import BuildingService from 'src/services/building';
import {IBuildingInfo} from 'src/services/building/type';
import StockService from 'src/services/stock';
import {IStockInfo} from 'src/services/stock/type';
import {showNotiErr} from 'src/utils/notification';
//
interface ChangeSheetProps {
  show: boolean;
  onHide: () => void;
  buildingDetail: IBuildingInfo | null;
  isUploadNew?: boolean;
  uploadSheetNames?: string[];
  excelFileUpload?: string | Blob | null;
  onChangeStockData?: (list: IStockInfo[]) => void;
  onReload: () => void;
}
interface ChangeSheetForm {
  worksheetStockNames: string[];
  worksheetDisplayIndexName: string;
}
//
const stockService = new StockService();
const buildingService = new BuildingService();
//
const ChangeSheetIndexModal: FC<ChangeSheetProps> = props => {
  const {show, onHide, buildingDetail, uploadSheetNames, isUploadNew, excelFileUpload, onChangeStockData, onReload} =
    props;
  const [form] = Form.useForm<ChangeSheetForm>();
  //
  const [loading, setLoading] = useState<boolean>(false);
  //
  const handleUploadNewExcel = (values: ChangeSheetForm) => {
    const {worksheetDisplayIndexName, worksheetStockNames} = values;
    if (worksheetDisplayIndexName && worksheetStockNames && excelFileUpload) {
      setLoading(true);
      const formData = new FormData();
      formData.append('file', excelFileUpload);
      //
      stockService
        .importStock(
          {
            projectId: buildingDetail?.projectId,
            buildingId: buildingDetail?._id,
            worksheetStockNames: worksheetStockNames.join(','),
            worksheetDisplayIndexName,
          },
          formData,
        )
        .then(res => {
          const {message, data} = res;
          notification.success({
            message,
          });
          const {fileId} = data;
          buildingService.updateBuilding(buildingDetail?._id || '', {
            fileId,
          });
          if (onChangeStockData) {
            stockService
              .listStock({
                buildingId: buildingDetail?._id || '',
                projectId: buildingDetail?.projectId || '',
                page: 1,
                results: 50,
              })
              .then(res => {
                const {data} = res;
                const {list} = data;
                onChangeStockData(list);
              })
              .catch(err => showNotiErr(err));
          }
        })
        .catch(err => {
          showNotiErr(err);
        })
        .finally(() => {
          form.resetFields(['worksheetStockNames', 'worksheetDisplayIndexName']);
          setLoading(false);
          onReload();
          onHide();
         
        });
    } else {
      form.setFields([
        {name: 'worksheetStockNames', errors: ['Trường này là bắt buộc']},
        {name: 'worksheetDisplayIndexName', errors: ['Trường này là bắt buộc']},
      ]);
    }
  };
  const handleUpdateSheetIndex = (values: ChangeSheetForm) => {
    const {worksheetDisplayIndexName, worksheetStockNames} = values;
    if (worksheetDisplayIndexName && worksheetStockNames) {
      setLoading(true);
      buildingService
        .updateBuilding(buildingDetail?._id || '', {
          worksheetDisplayIndexName,
          worksheetStockNames,
        })
        .then(res => {
          const {message} = res;
          notification.success({message});
          onHide();
          onReload();
        })
        .catch(err => showNotiErr(err))
        .finally(() => {
          setLoading(false);
        });
    } else {
      form.setFields([
        {name: 'worksheetStockNames', errors: ['Trường này là bắt buộc']},
        {name: 'worksheetDisplayIndexName', errors: ['Trường này là bắt buộc']},
      ]);
    }
  };
  return (
    <Form
      form={form}
      name="change_sheet_form"
      labelCol={{span: 24}}
      wrapperCol={{span: 24}}
      initialValues={
        isUploadNew
          ? {}
          : {
              worksheetIndexId: buildingDetail ? buildingDetail.worksheetStockIndexName : '',
              worksheetStockNames: buildingDetail ? buildingDetail.worksheetStockNames : [],
              worksheetDisplayIndexName: buildingDetail ? buildingDetail.worksheetDisplayIndexName : '',
            }
      }
      onFinish={isUploadNew ? handleUploadNewExcel : handleUpdateSheetIndex}
      autoComplete="off">
      <Modal
        open={show}
        cancelButtonProps={{
          loading,
          disabled: loading,
        }}
        maskClosable={false}
        confirmLoading={loading}
        onCancel={() => {
          form.resetFields(['worksheetStockNames', 'worksheetDisplayIndexName']);
          onHide();
        }}
        title={isUploadNew ? 'Khai báo tên sheet' : 'Chỉnh sửa thứ tự sheet'}
        onOk={form.submit}>
        {!isUploadNew && (
          <div style={{marginBottom: 15}}>
            <Button size="small" type="link">
              Link sheet tổng
            </Button>
          </div>
        )}

        <Form.Item<ChangeSheetForm>
          label="Danh sách sheet bảng hàng"
          name="worksheetStockNames"
          extra={<span>Sheet ở vị trí đầu tiên trong danh sách này sẽ là sheet hiển thị bảng hàng Độc Quyền</span>}
          rules={[{required: true, message: 'Trường này là bắt buộc!'}]}>
          <Select
            placeholder="Chọn các sheet có chứa bảng hàng"
            allowClear
            mode="multiple"
            defaultValue={form.getFieldValue('worksheetStockNames')}
            onChange={value => {
              form.setFieldValue('worksheetStockNames', value);
            }}
            options={
              uploadSheetNames
                ? uploadSheetNames.map(item => ({
                    value: item,
                    label: item,
                  }))
                : buildingDetail && buildingDetail.worksheetStockNames
                  ? buildingDetail?.worksheetStockNames.map(item => ({
                      value: item,
                      label: item,
                    }))
                  : []
            }
          />
        </Form.Item>

        <Form.Item<ChangeSheetForm>
          label="Tên sheet plan"
          name="worksheetDisplayIndexName"
          rules={[{required: true, message: 'Trường này là bắt buộc!'}]}>
          {isUploadNew && uploadSheetNames ? (
            <Select
              placeholder="Chọn tên sheet plan"
              allowClear
              onChange={value => {
                form.setFieldValue('worksheetDisplayIndexName', value);
              }}
              options={
                uploadSheetNames
                  ? uploadSheetNames.map(item => ({
                      value: item,
                      label: item,
                    }))
                  : []
              }
            />
          ) : (
            <Input placeholder="Nhập tên sheet plan" />
          )}
        </Form.Item>
      </Modal>
    </Form>
  );
};
export default ChangeSheetIndexModal;

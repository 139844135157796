import { Select, notification } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { AxiosError } from 'axios';
import { FC, useState } from 'react';
import { IUserInfo } from 'src/services/authentication/type';
import ProfileService from 'src/services/profile';
import { DEFAULT_NOTI_DURATION } from 'src/utils/common/constants';
import { EUserRole } from 'src/utils/common/enum';
//
interface IRolePickerProps {
  user: IUserInfo;
}
const dataOptions: DefaultOptionType[] = [
  {
    value: EUserRole.SUPER_ADMIN,
    label: 'Giám đốc',
    disabled: true,
  },
  {
    value: EUserRole.ADMIN,
    label: 'Admin',
  },
  {
    value: EUserRole.SALE_EXTERNAL,
    label: 'Sale đối tác',
  },
  {
    value: EUserRole.SALE_INTERNAL,
    label: 'Sale nội bộ',
  },
  {
    value: EUserRole.GUEST,
    label: 'Khách',
  },
];
const profileService = new ProfileService();
//
const RolePicker: FC<IRolePickerProps> = props => {
  const {user} = props;
  const [loading, setLoading] = useState<boolean>(false);
  const handleChange = async (value: string) => {
    setLoading(true);
    try {
      await profileService.updateProfile(user._id, {
        role: value,
      });
    } catch (err) {
      if (err instanceof AxiosError) {
        notification.error({
          message: 'Có lỗi xảy ra',
          description: err.response?.data.message,
          duration: DEFAULT_NOTI_DURATION,
        });
      }
    }
    setLoading(false);
  };
  return (
    <Select
      loading={loading}
      options={dataOptions}
      defaultValue={user.role}
      style={{width: 120}}
      disabled={user.role === EUserRole.SUPER_ADMIN}
      onChange={handleChange}
    />
  );
};
export default RolePicker;

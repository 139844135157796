import { CheckCircleOutlined } from '@ant-design/icons';
import { Button, Popconfirm, notification } from 'antd';
import { FC, useState } from 'react';
import { IUserInfo } from 'src/services/authentication/type';
import ProfileService from 'src/services/profile';
import { showNotiErr } from 'src/utils/notification';
//
interface IProps {
  user: IUserInfo;
  onReload: () => void;
}

const profileService = new ProfileService();
//
const ActivateUserBtn: FC<IProps> = props => {
  const {user, onReload} = props;
  const [loading, setLoading] = useState<boolean>(false);
  const handleActivateUser = async () => {
    setLoading(true);
    profileService
      .updateProfile(user._id, {active: true})
      .then(res => {
        const {message} = res;
        notification.success({message});
        onReload();
      })
      .catch(err => showNotiErr(err))
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <Popconfirm
      title={`Bạn chắc chắn muốn kích hoạt tài khoản này?`}
      okButtonProps={{loading}}
      onConfirm={handleActivateUser}
      cancelButtonProps={{loading}}>
      <Button loading={loading} disabled={user.active} type="primary" icon={<CheckCircleOutlined />}></Button>
    </Popconfirm>
  );
};
export default ActivateUserBtn;

import {Breadcrumb, Button, Col, Flex, Form, Input, InputNumber, Row, Switch, Typography, notification} from 'antd';
import {FC, useEffect, useState} from 'react';
import ReactQuill from 'react-quill';
//
import 'react-quill/dist/quill.snow.css';
import LinkAndOverviewRowForm from 'src/components/common/addLinksAndOverview';
import ProjectService from 'src/services/project';
import {ICRUDProjectPayload} from 'src/services/project/type';
import {toggleReloadProject} from 'src/stores/common/layout.slice';
import {useAppDispatch} from 'src/stores/utils';
import {DEFAULT_QUILL_FORMAT, DEFAULT_QUILL_MODULES} from 'src/utils/common/constants';
import {checkIsSuperAdmin} from 'src/utils/common/function';
import {showNotiErr} from 'src/utils/notification';
//
const {Title} = Typography;
const projectService = new ProjectService();
//
const AddProjectScreen: FC = () => {
  const dispatch = useAppDispatch();
  //
  const [form] = Form.useForm<ICRUDProjectPayload>();
  const [loading, setLoading] = useState<boolean>(false);
  //
  useEffect(() => {
    getNewOrderNumber();
  }, []);
  //
  const getNewOrderNumber = async () => {
    projectService
      .listProject({results: 50, page: 1, sortField: 'orderBy', sortOrder: 'ascend'})
      .then(res => {
        const {data} = res;
        const {list} = data;
        const lastItem = list[list.length - 1];
        if (lastItem.orderBy) {
          form.setFieldValue('orderBy', lastItem.orderBy + 1);
        } else {
          form.setFieldValue('orderBy', 1);
        }
      })
      .catch(err => showNotiErr(err));
  };
  //
  const onFinish = async (values: ICRUDProjectPayload) => {
    setLoading(true);
    projectService
      .createProject(values)
      .then(res => {
        const {message} = res;
        notification.success({
          message,
        });
        dispatch(toggleReloadProject());
        form.resetFields();
      })
      .catch(err => showNotiErr(err))
      .finally(() => setLoading(false));
  };

  return (
    <Form form={form} labelCol={{span: 24}} wrapperCol={{span: 24}} autoComplete="off" onFinish={onFinish}>
      <Flex justify="space-between" align="center">
        <div>
          <Title level={3}>Thêm dự án</Title>
          <Breadcrumb>
            <Breadcrumb.Item href="/">Trang chủ</Breadcrumb.Item>
            <Breadcrumb.Item>Thêm dự án</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        {checkIsSuperAdmin() ? (
          <Button loading={loading} size="large" title="Lưu" type="primary" htmlType="submit">
            Lưu thông tin
          </Button>
        ) : (
          <></>
        )}
      </Flex>

      <div className="building-form-container">
        <Row gutter={16}>
          <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24} span={8}>
            <Row gutter={8}>
              <Col xxl={16} xl={16} lg={16} md={24} sm={24} xs={24} span={16}>
                <Form.Item<ICRUDProjectPayload>
                  label="Tên dự án"
                  name="name"
                  rules={[{required: true, message: 'Trường này là bắt buộc!'}]}>
                  <Input size="large" placeholder="Nhập tên dự án" />
                </Form.Item>
              </Col>
              <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24} span={8}>
                <Form.Item<ICRUDProjectPayload>
                  label="Thứ tự"
                  name="orderBy"
                  rules={[{required: true, message: 'Trường này là bắt buộc!'}]}>
                  <InputNumber size="large" placeholder="Thứ tự" min={1} stringMode={false} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={8}>
              <Col xxl={16} xl={16} lg={16} md={24} sm={24} xs={24} span={16}>
                <Form.Item<ICRUDProjectPayload>
                  label="Tên viết tắt"
                  name="shortName"
                  rules={[{required: true, message: 'Trường này là bắt buộc!'}]}>
                  <Input size="large" placeholder="Nhập tên dự án" />
                </Form.Item>
              </Col>

              <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24} span={8}>
                <Form.Item<ICRUDProjectPayload> label="Ẩn dự án" name="isPrivate">
                  <Switch />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item<ICRUDProjectPayload> label="Thông tin thanh toán" name="paymentInfo">
              <Input size="large" placeholder="Nhập đường dẫn" />
            </Form.Item>
            <Form.Item<ICRUDProjectPayload> label="Thông tin hỗ trợ" name="supportInfo">
              <Input size="large" placeholder="Nhập đường dẫn" />
            </Form.Item>

            <Form.Item<ICRUDProjectPayload> label="Link Zalo hỗ trợ" name="linkZalo">
              <Input size="large" placeholder="Nhập link Zalo hỗ trợ" />
            </Form.Item>
          </Col>
          <Col xxl={16} xl={16} lg={16} md={24} sm={24} xs={24} span={16}>
            <Form.Item<ICRUDProjectPayload>
              label="Mô tả dự án"
              name="description"
              rules={[{required: true, message: 'Trường này là bắt buộc!'}]}>
              <ReactQuill
                style={{height: 350}}
                theme="snow"
                placeholder="Nhập mô tả dự án"
                modules={DEFAULT_QUILL_MODULES}
                formats={DEFAULT_QUILL_FORMAT}
                value={form.getFieldValue('content')}
                onChange={value => form.setFieldValue('content', value)}
              />
            </Form.Item>
          </Col>
        </Row>

        <LinkAndOverviewRowForm type="project" />
      </div>
    </Form>
  );
};

export default AddProjectScreen;

import {InboxOutlined, ReloadOutlined} from '@ant-design/icons';
import {Button, Col, Flex, FormInstance, Row, Spin, Table, Typography, Upload} from 'antd';
import {FC, Fragment, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import useWindowSize from 'src/hooks/useWindowSize';
import {IBuildingInfo, ICRUDBuildingPayload} from 'src/services/building/type';
import StockService from 'src/services/stock';
import {IStockInfo} from 'src/services/stock/type';
import {DEFAULT_EXCEL_SAMPLE} from 'src/utils/common/constants';
import {checkIsAdmin, checkIsSuperAdmin, getStockTableColumn} from 'src/utils/common/function';
import {showNotiErr} from 'src/utils/notification';
import {read} from 'xlsx';
import CanSeePicker from './components/changeCanSeePicker';
import ChangeSheetIndexModal from './components/changeSheetIndexModal';
import {getTableHorizontalSpace} from 'src/styles/utils';
//
interface IProps {
  form: FormInstance<ICRUDBuildingPayload>;
  detail: IBuildingInfo | null;
  onReload: () => void;
}
//
const {Text} = Typography;
const stockService = new StockService();
//
const {Dragger} = Upload;
//
const BuildingTableForm: FC<IProps> = props => {
  const {commonTableHeight} = useWindowSize();
  const {detail, onReload} = props;
  const tableColumn = getStockTableColumn(detail);
  //

  const [loadingExisted, setLoadingExisted] = useState<boolean>(false);
  const [stockData, setStockData] = useState<IStockInfo[]>([]);
  const [existedStockData, setExistedStockData] = useState<IStockInfo[]>([]);
  const [needUpdateTable, setNeedUpdateTable] = useState<boolean>(false);
  const [showChangeSheet, setShowChangeSheet] = useState<boolean>(false);
  const [loadingFile, setLoadingFile] = useState<boolean>(false);
  const [googleSheetLink, setGoogleSheetLink] = useState<string>('');
  const [uploadSheetName, setUploadSheetName] = useState<string[]>([]);
  const [excelUploadFile, setExcelUploadFile] = useState<string | Blob | null>(null);
  //
  useEffect(() => {
    getExistedStockData();
    getGoogleSheetLink();
  }, [detail]);
  //
  const getExistedStockData = async () => {
    if (detail && (detail._id || detail.projectId)) {
      setLoadingExisted(true);
      const {worksheetStockNames} = detail;
      // Lấy danh sách toà nhà
      stockService
        .listStock({
          buildingId: detail?._id || '',
          projectId: detail?.projectId || '',
          page: 1,
          results: 50,
          sheetName: worksheetStockNames && worksheetStockNames.length > 0 ? worksheetStockNames[0] : undefined,
        })
        .then(res => {
          const {data} = res;
          const {list} = data;
          if (list.length > 0) {
            setExistedStockData(list);
          } else {
            setNeedUpdateTable(true);
          }
        })
        .catch(err => showNotiErr(err))
        .finally(() => setLoadingExisted(false));
    }
  };

  const getGoogleSheetLink = async () => {
    if (detail && detail.fileId) {
      setLoadingFile(true);
      // Get File excel
      stockService
        .renderHTMLJSON(detail.fileId)
        .then(htmlRes => {
          const {data} = htmlRes;
          const {spreadsheetEditLink} = data;
          setGoogleSheetLink(spreadsheetEditLink);
        })
        .catch(err => showNotiErr(err))
        .finally(() => setLoadingFile(false));
    } else return;
  };
  //
  const customRequest = async (file: string | Blob) => {
    setExcelUploadFile(file);
    const reader = new FileReader();
    reader.onload = function (e) {
      const data = e.target?.result;
      let readerData = read(data, {type: 'binary'});
      const {SheetNames} = readerData;
      setUploadSheetName(SheetNames);
      setShowChangeSheet(true);
    };
    reader.readAsBinaryString(file as Blob);
  };
  const handleChangeScreen = () => {
    setNeedUpdateTable(prev => !prev);
    setStockData([]);
  };
  const handleToggleChangeSheet = () => {
    setShowChangeSheet(prev => !prev);
  };
  const handleUpdateStock = async (id: string) => {
    setLoadingExisted(true);
    stockService
      .getDetail(id)
      .then(res => {
        const {data} = res;
        let newStockList = [...existedStockData];
        newStockList.forEach(item => {
          if (item._id === data._id) {
            item.canRead = data.canRead;
          }
        });
        setExistedStockData(newStockList);
      })
      .catch(err => showNotiErr(err))
      .finally(() => setLoadingExisted(false));
  };

  //
  if (needUpdateTable) {
    return (
      <Row>
        <Col span={24}>
          <Flex vertical align="center" gap={8}>
            {existedStockData.length > 0 && (
              <Button onClick={handleChangeScreen} size="small" type="link">
                Quay lại bảng hàng hiện tại
              </Button>
            )}
            <Dragger
              showUploadList={false}
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .csv"
              customRequest={({file}) => customRequest(file)}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Click hoặc kéo thả file excel vào đây để import bảng hàng</p>
              <p className="ant-upload-hint">Để việc import được thuận lợi, vui lòng sử dụng file Excel mẫu</p>
              <p className="ant-upload-hint">Để dữ liệu được lưu đúng cách, vui lòng tuân thủ các quy tắc sau:</p>
              <div>
                <p className="ant-upload-hint">- Cột đầu tiên luôn luôn là Mã Căn</p>
                <p className="ant-upload-hint">- Sheet chứa bảng hàng chỉ cần 1 hàng đầu mục, không cần nhiều hàng</p>
                <p className="ant-upload-hint">
                  - Nếu có nhiều hơn vui lòng gộp thành 1 hàng, dữ liệu nếu không có ở cột nào thì sẽ để trống ở cột đó
                </p>
                <p className='ant-upload-hint'>- Các cột ở các sheet bảng hàng là phải giống hệt nhau</p>
              </div>
            </Dragger>
            <Link to={DEFAULT_EXCEL_SAMPLE} target="_blank" download style={{textAlign: 'center'}}>
              Link xem file bảng hàng mẫu
            </Link>
          </Flex>
        </Col>

        <Col span={24} style={{marginTop: 20}}>
          <Text>Preview:</Text>
          {tableColumn.length > 0 && stockData.length > 0 ? (
            <Table
              bordered
              dataSource={stockData}
              size="small"
              scroll={{x: getTableHorizontalSpace(), y: commonTableHeight}}
              columns={tableColumn}
            />
          ) : (
            <></>
          )}
        </Col>
        {loadingExisted && <Spin fullscreen />}
        <ChangeSheetIndexModal
          excelFileUpload={excelUploadFile}
          show={showChangeSheet}
          onHide={handleToggleChangeSheet}
          buildingDetail={detail}
          uploadSheetNames={uploadSheetName}
          isUploadNew
          onChangeStockData={setStockData}
          onReload={onReload}
        />
      </Row>
    );
  } else {
    return (
      <Fragment>
        <Flex align="center" justify="space-between" style={{marginBottom: 10}}>
          <Flex align="center" gap={8} wrap="wrap">
            <Button
              loading={loadingFile}
              size="small"
              type="link"
              onClick={() => window.open(googleSheetLink, '_blank')}>
              Link sheet tổng
            </Button>
            <Button size="small" type="primary" onClick={handleToggleChangeSheet}>
              Sửa thứ tự sheet
            </Button>
            <Button size="small" type="primary" onClick={handleChangeScreen}>
              Upload bảng hàng mới
            </Button>
          </Flex>
          <Button onClick={onReload} title="Tải lại" icon={<ReloadOutlined />} size="small" type="primary">
            Tải lại
          </Button>
        </Flex>
        <Table
          loading={loadingExisted}
          sticky
          rowKey={record => record._id}
          bordered
          dataSource={existedStockData}
          size="small"
          scroll={{x: getTableHorizontalSpace(), y: commonTableHeight}}
          columns={
            checkIsAdmin() || checkIsSuperAdmin()
              ? [
                  ...tableColumn,
                  {
                    title: 'Các quyền được nhìn thấy',
                    key: 'decentralization',
                    render: (_, dataItem) => <CanSeePicker stock={dataItem} onUpdateStock={handleUpdateStock} />,
                    width: 250,
                    fixed: 'right',
                  },
                ]
              : tableColumn
          }
        />
        <ChangeSheetIndexModal
          show={showChangeSheet}
          onHide={handleToggleChangeSheet}
          buildingDetail={detail}
          onReload={onReload}
        />
      </Fragment>
    );
  }
};
export default BuildingTableForm;

import {DeleteOutlined} from '@ant-design/icons';
import {Button, Popconfirm, notification} from 'antd';
import {FC, useState} from 'react';
import {IUserInfo} from 'src/services/authentication/type';
import ProfileService from 'src/services/profile';
import {showNotiErr} from 'src/utils/notification';
//
interface IProps {
  user: IUserInfo;
  onReload: () => void;
}

const profileService = new ProfileService();
//
const DeleteUserBtn: FC<IProps> = props => {
  const {user, onReload} = props;
  const [loading, setLoading] = useState<boolean>(false);
  const handleDeleteUser = async () => {
    setLoading(true);
    profileService
      .deleteUser(user._id)
      .then(res => {
        const {message} = res;
        notification.success({message});
        onReload();
      })
      .catch(err => showNotiErr(err))
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <Popconfirm
      title={`Bạn chắc chắn muốn xoá ${user.displayName}?`}
      okButtonProps={{loading}}
      onConfirm={handleDeleteUser}
      cancelButtonProps={{loading}}
      description="Tài khoản sau khi bị xoá khỏi hệ thống sẽ không thể khôi phục lại.">
      <Button loading={loading} danger type="dashed" icon={<DeleteOutlined />}></Button>
    </Popconfirm>
  );
};
export default DeleteUserBtn;

import {
  AntDesignOutlined,
  DollarOutlined,
  EditOutlined,
  GroupOutlined,
  HomeOutlined,
  InsertRowRightOutlined,
  SendOutlined,
  ShoppingCartOutlined,
  SmileOutlined,
  TagOutlined,
} from '@ant-design/icons';
import {Button, Col, Divider, Flex, Modal, Row, Typography, notification} from 'antd';
import {FC, Fragment, useEffect, useMemo, useState} from 'react';
import {Link} from 'react-router-dom';
import useWindowSize from 'src/hooks/useWindowSize';
import {IBuildingInfo} from 'src/services/building/type';
import {IProjectInfo} from 'src/services/project/type';
import StockService from 'src/services/stock';
import {IStockInfo} from 'src/services/stock/type';
import {useAppSelector} from 'src/stores/utils';
import {
  checkIsAdmin,
  checkIsSuperAdmin,
  convertMoney,
  findExcelObjectByKey,
  findExcelObjectByMultiKey,
  findExcelObjectContainKey,
} from 'src/utils/common/function';
import {showNotiErr} from 'src/utils/notification';
//
interface IProps {
  selectedCell: HTMLTableCellElement | null;
  show: boolean;
  onHide: () => void;
  selectedStockCode: string;
  projectId: string;
  buildingDetail: IBuildingInfo | null;
  canEdit?: boolean;
  projectDetail: IProjectInfo | null;
}
//
const {Title, Text, Paragraph} = Typography;
const stockService = new StockService();
//
const DetailModal: FC<IProps> = props => {
  const {windowSize} = useWindowSize();
  const {isLogin} = useAppSelector(state => state.auth);
  //
  const {show, onHide, selectedStockCode, projectId, selectedCell, projectDetail, buildingDetail} = props;
  //
  const cellPosition = useMemo(() => {
    if (selectedCell) {
      const cellStyle = selectedCell.getBoundingClientRect();
      const {top, right, left} = cellStyle;
      return {
        top: top - windowSize.height * 0.3,
        right: right - windowSize.width * 0.3,
        left: left - windowSize.width * 0.3,
      };
    } else return {};
  }, [selectedCell]);
  //
  const [stockInfo, setStockInfo] = useState<IStockInfo | null>(null);
  const [loadingStock, setLoadingStock] = useState<boolean>(false);
  //
  useEffect(() => {
    getStockInfo();
  }, [selectedStockCode, show]);

  //
  const getStockInfo = async () => {
    if (show && !selectedStockCode.includes('undefined')) {
      setLoadingStock(true);
      stockService
        .getDetailByCode(projectId, selectedStockCode || '')
        .then(res => {
          const {data} = res;
          setStockInfo(data);
        })
        .catch(err => {})
        .finally(() => setLoadingStock(false));
    } else return;
  };
  //
  const onLinkClick = async (link: string, fieldLabel: string) => {
    if (isLogin) {
      window.open(link, '_blank');
      // Gửi event quan tâm
      if (!checkIsAdmin() && !checkIsSuperAdmin()) {
        stockService
          .sendClickEvent(projectId, selectedStockCode, {eventName: 'clickLink', eventValue: `Quan tâm ${fieldLabel}`})
          .then(res => {})
          .catch(err => showNotiErr(err));
      }
    } else {
      notification.destroy();
      notification.info({
        message: 'Bạn cần phải đăng nhập để sử dụng đầy đủ tính năng',
        btn: (
          <Button
            onClick={() => {
              window.location.href = '/signin';
            }}>
            Đăng nhập
          </Button>
        ),
        closable: false,
      });
    }
  };
  let houseTypeObj = useMemo(
    () => (stockInfo ? findExcelObjectContainKey(stockInfo.properties, 'loai') : null),
    [stockInfo],
  );
  let directionObj = useMemo(
    () => (stockInfo ? findExcelObjectByKey(stockInfo.properties, 'huong') : null),
    [stockInfo],
  );
  let acreageObj = useMemo(
    () => (stockInfo ? findExcelObjectByMultiKey(stockInfo.properties, ['dien-tich']) : null),
    [stockInfo],
  );
  let noteObj = useMemo(
    () => (stockInfo ? findExcelObjectByMultiKey(stockInfo.properties, ['note', 'ghi-chu']) : null),
    [stockInfo],
  );
  let statusObj = useMemo(
    () => (stockInfo ? findExcelObjectByMultiKey(stockInfo.properties, ['tinh-trang', 'trang-thai']) : null),
    [stockInfo],
  );
  let groupObj = useMemo(
    () => (stockInfo ? findExcelObjectContainKey(stockInfo.properties, 'quy') : null),
    [stockInfo],
  );
  return (
    <Modal
      styles={{
        content: {
          padding: 10,
          width: 360,
        },
      }}
      style={cellPosition}
      mask={false}
      closable={false}
      loading={loadingStock}
      open={show}
      onCancel={onHide}
      afterClose={() => {
        setStockInfo(null);
      }}
      footer={[]}>
      <Fragment>
        <Flex justify="center" style={{backgroundColor: '#f67b13', borderTopLeftRadius: 8, borderTopRightRadius: 8}}>
          <Title level={4} style={{color: 'white'}}>
            {selectedStockCode}
          </Title>
        </Flex>
        <Flex vertical align="center" style={{backgroundColor: '#ededed', paddingTop: 10}}>
          <Flex align="center" justify="center" gap={10}>
            <ShoppingCartOutlined style={{fontSize: 24, color: '#f67b13'}} />
            <Title level={4} style={{color: '#f67b13'}}>
              {convertMoney(
                findExcelObjectByMultiKey(stockInfo?.properties || [], [
                  'gia-full-vatkpbt',
                  'tong-gia-tri-can-ho-gom-vatkbpt',
                  'tong-gia-chua-vat-kpbt',
                  'vat-kpbt',
                  'vatkpbt',
                ])?.value,
              )}
            </Title>
          </Flex>
          <Paragraph>Giá đã bao gồm VAT & KPBT</Paragraph>
          {projectDetail && projectDetail.linkZalo ? (
            <Link to={projectDetail?.linkZalo || ''} target="_blank">
              Liên hệ Admin
            </Link>
          ) : (
            <></>
          )}

          {buildingDetail && buildingDetail.salesPolicyLink ? (
            <Link to={buildingDetail.salesPolicyLink} target="_blank" style={{color: '#f67b13', textAlign: 'center'}}>
              Chính sách bán hàng
            </Link>
          ) : (
            <Paragraph style={{color: '#f67b13', textAlign: 'center'}}>Chưa cập nhật chính sách bán hàng</Paragraph>
          )}
        </Flex>
        <div style={{paddingTop: 15, paddingBottom: 15, marginBottom: 15, backgroundColor: '#f9f9f9'}}>
          <Row gutter={8} style={{paddingBlock: 10, margin: 0}}>
            {stockInfo && stockInfo.properties ? (
              stockInfo.properties.map((item, index) => {
                if (
                  (item.key.includes('gia') ||
                    item.key.includes('tts') ||
                    item.key.includes('tttd') ||
                    item.key.includes('vay')) &&
                  !item.key.includes('gia-full') &&
                  !item.key.includes('tong')
                )
                  return item.value ? (
                    <Col span={8}>
                      <Flex vertical align="center" gap={5} key={index}>
                        <Flex align="center" justify="center" gap={8}>
                          <DollarOutlined style={{fontSize: 12}} />
                          <Text style={{textAlign: 'center', fontSize: 12, color: '#5e5e5e'}}>
                            {item.label.replace('(TẠM TÍNH)', '')}
                          </Text>
                        </Flex>
                        <Title level={5} style={{color: '#f67b13'}}>
                          {convertMoney(item.value, 'tỷ')}
                        </Title>
                      </Flex>
                    </Col>
                  ) : (
                    <></>
                  );
              })
            ) : (
              <></>
            )}
          </Row>
          <Divider />
          <Row gutter={8} style={{paddingBlock: 10, margin: 0, rowGap: 16}}>
            {stockInfo && stockInfo.properties ? (
              <>
                <Col span={8}>
                  <Flex vertical align="center" gap={5}>
                    <Flex align="center" justify="center" gap={8}>
                      <HomeOutlined style={{fontSize: 12}} />
                      <Text style={{textAlign: 'center', fontSize: 12}}>Loại căn</Text>
                    </Flex>
                    {houseTypeObj && houseTypeObj.value ? (
                      <Title level={5}>{houseTypeObj.value}</Title>
                    ) : (
                      <Text>-</Text>
                    )}
                  </Flex>
                </Col>

                <Col span={8}>
                  <Flex vertical align="center" gap={5}>
                    <Flex align="center" justify="center" gap={8}>
                      <SendOutlined style={{fontSize: 12}} />
                      <Text style={{textAlign: 'center', fontSize: 12}}>Hướng</Text>
                    </Flex>
                    {directionObj && directionObj.value ? (
                      <Title level={5}>{directionObj.value}</Title>
                    ) : (
                      <Text>-</Text>
                    )}
                  </Flex>
                </Col>

                <Col span={8}>
                  <Flex vertical align="center" gap={5}>
                    <Flex align="center" justify="center" gap={8}>
                      <InsertRowRightOutlined style={{fontSize: 12}} />
                      <Text style={{textAlign: 'center', fontSize: 12}}>Diện tích</Text>
                    </Flex>
                    {acreageObj && acreageObj.value ? <Title level={5}>{acreageObj.value} m2</Title> : <Text>-</Text>}
                  </Flex>
                </Col>
                <Col span={8}>
                  <Flex vertical align="center" gap={5}>
                    <Flex align="center" justify="center" gap={8}>
                      <GroupOutlined style={{fontSize: 12}} />
                      <Text style={{textAlign: 'center', fontSize: 12}}>Quỹ</Text>
                    </Flex>
                    <div
                      style={{
                        backgroundColor: '#f67b13',
                        borderRadius: 4,
                        paddingLeft: 4,
                        paddingRight: 4,
                      }}>
                      <Paragraph style={{textAlign: 'center', margin: 'auto', color: 'white'}}>
                        {groupObj && groupObj.value ? groupObj.value : 'Độc quyền'}
                      </Paragraph>
                    </div>
                  </Flex>
                </Col>
                <Col span={8}>
                  <Flex vertical align="center" gap={5}>
                    <Flex align="center" justify="center" gap={8}>
                      <SmileOutlined style={{fontSize: 12}} />
                      <Text style={{textAlign: 'center', fontSize: 12}}>Tình trạng</Text>
                    </Flex>
                    {statusObj && statusObj.value ? (
                      <div
                        style={{
                          backgroundColor: 'green',
                          borderRadius: 4,
                          paddingLeft: 4,
                          paddingRight: 4,
                        }}>
                        <Paragraph style={{textAlign: 'center', margin: 'auto', color: 'white'}}>
                          {statusObj.value}
                        </Paragraph>
                      </div>
                    ) : (
                      <Text>-</Text>
                    )}
                  </Flex>
                </Col>

                <Col span={8}>
                  <Flex vertical align="center" gap={5}>
                    <Flex align="center" justify="center" gap={8}>
                      <EditOutlined style={{fontSize: 12}} />
                      <Text style={{textAlign: 'center', fontSize: 12}}>Ghi chú</Text>
                    </Flex>
                    {noteObj && noteObj.value ? (
                      <Title level={5} style={{color: '#f67b13'}}>
                        {noteObj.value}
                      </Title>
                    ) : (
                      <Text>-</Text>
                    )}
                  </Flex>
                </Col>
              </>
            ) : (
              <></>
            )}
          </Row>
        </div>

        <Flex vertical style={{padding: 10}}>
          <Flex align="center" gap={10}>
            <TagOutlined style={{fontSize: 20}} />
            <Title level={5}>TÀI LIỆU BÁN HÀNG</Title>
          </Flex>
          <div style={{marginTop: 8, marginLeft: 8}}>
            {stockInfo && stockInfo.properties ? (
              stockInfo.properties.map((item, index) => {
                if (item.textLink) {
                  return (
                    <Button
                      onClick={() => onLinkClick(item.textLink || '', item.label)}
                      icon={<AntDesignOutlined />}
                      type="link">
                      {item.label}
                    </Button>
                  );
                }
              })
            ) : (
              <></>
            )}
          </div>
        </Flex>
        <Divider />
      </Fragment>
    </Modal>
  );
};
export default DetailModal;

import {FC} from 'react';
import parse from 'html-react-parser';
import {Empty} from 'antd';
import './styles.scss';
//
interface IProps {
  content: string | null | undefined;
}
//
const HTMLParser: FC<IProps> = props => {
  const {content} = props;
  if (content) {
    return <div className="html-parser-container">{parse(content)}</div>;
  } else return <Empty />;
};
export default HTMLParser;

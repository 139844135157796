import {Breadcrumb, Button, Col, Flex, Form, Input, Row, Select, Spin, Typography, notification} from 'antd';
import {FC, useEffect, useState} from 'react';
import ReactQuill from 'react-quill';
//
import 'react-quill/dist/quill.snow.css';
import {useNavigate, useParams} from 'react-router-dom';
import LinkAndOverviewRowForm from 'src/components/common/addLinksAndOverview';
import {ROUTE_CONFIG} from 'src/routes/config';
import ScreenParams from 'src/routes/screenParams';
import BlockService from 'src/services/block';
import {IBlockInfo, ICRUDBlockPayload} from 'src/services/block/type';
import ProjectService from 'src/services/project';
import {IProjectInfo} from 'src/services/project/type';
import {toggleReloadProject} from 'src/stores/common/layout.slice';
import {useAppDispatch} from 'src/stores/utils';
import {DEFAULT_QUILL_FORMAT, DEFAULT_QUILL_MODULES} from 'src/utils/common/constants';
import {showNotiErr} from 'src/utils/notification';
//
const {Title} = Typography;
const blockService = new BlockService();
const projectService = new ProjectService();
//
const UpdateBlockScreen: FC = () => {
  const {id} = useParams<ScreenParams['UPDATE_BLOCK']>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  //
  const [form] = Form.useForm<ICRUDBlockPayload>();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingDetail, setLoadingDetail] = useState<boolean>(false);
  const [blockDetail, setBlockDetail] = useState<IBlockInfo | null>(null);
  const [loadingProject, setLoadingProject] = useState<boolean>(false);
  const [listProject, setListProject] = useState<IProjectInfo[]>([]);
  //
  useEffect(() => {
    getBlockInfo();
  }, [id]);
  //
  const getBlockInfo = async () => {
    if (id) {
      setLoadingDetail(true);
      blockService
        .getBlockDetail(id)
        .then(res => {
          const {data} = res;
          setBlockDetail(data);
          form.setFieldsValue({
            description: data.description,
            links: data.links || [],
            overviews: data.overviews || [],
            name: data.name,
            projectId: data.projectId || undefined,
          });
        })
        .catch(err => showNotiErr(err))
        .finally(() => setLoadingDetail(false));
    }
  };
  //
  const onFinish = async (values: ICRUDBlockPayload) => {
    setLoading(true);
    blockService
      .updateBlock(id || '', {
        projectId: blockDetail?.projectId || values.projectId || '',
        name: values.name,
        description: values.description,
        links: values.links,
        overviews: values.overviews,
      })
      .then(res => {
        const {message, data} = res;
        notification.success({
          message,
        });
        dispatch(toggleReloadProject());
        navigate(`${ROUTE_CONFIG.BLOCK_INFO}/${data._id}`);
      })
      .catch(err => showNotiErr(err))
      .finally(() => setLoading(false));
  };
  //
  const getListProjects = async () => {
    setLoadingProject(true);
    projectService
      .listProject({
        page: 1,
        results: 50,
        sortField: 'orderBy',
        sortOrder: 'ascend',
      })
      .then(res => {
        const {data} = res;
        const {list} = data;
        setListProject(list);
      })
      .catch(err => showNotiErr(err))
      .finally(() => setLoadingProject(false));
  };
  return (
    <Form form={form} labelCol={{span: 24}} wrapperCol={{span: 24}} autoComplete="off" onFinish={onFinish}>
      <Flex justify="space-between" align="center">
        <div>
          <Title level={3}>Sửa phân khu</Title>
          <Breadcrumb>
            <Breadcrumb.Item href="/">Trang chủ</Breadcrumb.Item>
            <Breadcrumb.Item href={`${ROUTE_CONFIG.PROJECT_INFO}/${id}`}>{blockDetail?.project?.name}</Breadcrumb.Item>
            <Breadcrumb.Item>{blockDetail?.name}</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <Button loading={loading} size="large" title="Lưu" type="primary" htmlType="submit">
          Lưu thông tin
        </Button>
      </Flex>

      <div className="building-form-container">
        <Row gutter={16}>
          <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24} span={8}>
            <Form.Item<ICRUDBlockPayload>
              label="Tên phân khu"
              name="name"
              rules={[{required: true, message: 'Trường này là bắt buộc!'}]}>
              <Input size="large" placeholder="Nhập tên dự án" />
            </Form.Item>
            {blockDetail && !blockDetail.project ? (
              <Form.Item<ICRUDBlockPayload> label="Thuộc dự án" name="projectId">
                <Select
                  onDropdownVisibleChange={open => (open ? getListProjects() : null)}
                  size="large"
                  options={listProject}
                  fieldNames={{value: '_id', label: 'shortName'}}
                  placeholder="Chọn dự án"
                  value={form.getFieldValue('projectId')}
                  onChange={value => form.setFieldValue('projectId', value)}
                  loading={loadingProject}
                />
              </Form.Item>
            ) : (
              <></>
            )}
          </Col>
          <Col xxl={16} xl={16} lg={16} md={24} sm={24} xs={24} span={16}>
            <Form.Item<ICRUDBlockPayload>
              label="Mô tả phân khu"
              name="description"
              rules={[{required: true, message: 'Trường này là bắt buộc!'}]}>
              <ReactQuill
                style={{height: 350}}
                theme="snow"
                placeholder="Nhập mô tả phân khu"
                modules={DEFAULT_QUILL_MODULES}
                formats={DEFAULT_QUILL_FORMAT}
                value={form.getFieldValue('content')}
                onChange={value => form.setFieldValue('content', value)}
              />
            </Form.Item>
          </Col>
        </Row>

        <LinkAndOverviewRowForm type="block" />
      </div>
      {loadingDetail && <Spin fullscreen />}
    </Form>
  );
};

export default UpdateBlockScreen;

import {Col, Form, FormInstance, Input, Row, Select} from 'antd';
import {FC, Fragment, useState} from 'react';
import ReactQuill from 'react-quill';
import LinkAndOverviewRowForm from 'src/components/common/addLinksAndOverview';
import BlockService from 'src/services/block';
import {IBlockInfo} from 'src/services/block/type';
import {IBuildingInfo, ICRUDBuildingPayload} from 'src/services/building/type';
import {DEFAULT_QUILL_FORMAT, DEFAULT_QUILL_MODULES} from 'src/utils/common/constants';
import {showNotiErr} from 'src/utils/notification';
interface IProps {
  form: FormInstance<ICRUDBuildingPayload>;
  detail: IBuildingInfo | null;
}

const blockService = new BlockService();

//
const InformationForm: FC<IProps> = props => {
  const {form, detail} = props;
  //
  const [loadingBlock, setLoadingBlock] = useState<boolean>(false);
  const [listBlock, setListBlock] = useState<IBlockInfo[]>([]);

  const getListBlock = async () => {
    const projectId = detail?.projectId;
    if (projectId) {
      setLoadingBlock(true);
      blockService
        .listBlock()
        .then(res => {
          const {data} = res;
          const {list} = data;

          const blockList = list.filter(item => item.projectId && item.projectId === projectId);
          setListBlock(blockList);
        })
        .catch(err => showNotiErr(err))
        .finally(() => setLoadingBlock(false));
    } else return;
  };

  return (
    <Fragment>
      <Row gutter={16}>
        <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24} span={8}>
          <Form.Item<ICRUDBuildingPayload>
            label="Tên toà nhà"
            name="name"
            rules={[{required: true, message: 'Trường này là bắt buộc!'}]}>
            <Input size="large" placeholder="Nhập tên toà nhà" />
          </Form.Item>
          <Form.Item<ICRUDBuildingPayload>
            label="Mã toà nhà"
            name="shortName"
            rules={[{required: true, message: 'Trường này là bắt buộc!'}]}
            extra={<span>Vui lòng đặt mã toà nhà khớp với mã toà nhà của các căn trong file excel</span>}
            >
            <Input size="large" placeholder="Nhập mã toà nhà, VD: U38, L27, ..." />
          </Form.Item>
          {!form.getFieldValue('blockId') && (
            <Form.Item<ICRUDBuildingPayload> label="Thuộc phân khu" name="blockId">
              <Select
                onDropdownVisibleChange={open => (open ? getListBlock() : null)}
                size="large"
                options={listBlock}
                fieldNames={{value: '_id', label: 'name'}}
                placeholder="Chọn phân khu"
                value={form.getFieldValue('buildingId')}
                onChange={value => form.setFieldValue('buildingId', value)}
                loading={loadingBlock}
              />
            </Form.Item>
          )}
          <Form.Item<ICRUDBuildingPayload> label="Chính sách bán hàng" name="salesPolicyLink">
            <Input size="large" placeholder="Nhập đường dẫn" />
          </Form.Item>

          <Form.Item<ICRUDBuildingPayload> label="Phiếu tính giá" name="priceSheetLink">
            <Input size="large" placeholder="Nhập đường dẫn" />
          </Form.Item>
          <Form.Item<ICRUDBuildingPayload> label="Sa bàn 360" name="view360Link">
            <Input size="large" placeholder="Nhập đường dẫn sa bàn 360" />
          </Form.Item>
        </Col>
        <Col xxl={16} xl={16} lg={16} md={24} sm={24} xs={24}  span={16}>
          <Form.Item<ICRUDBuildingPayload>
            label="Mô tả toà nhà"
            name="content"
            rules={[{required: true, message: 'Trường này là bắt buộc!'}]}>
            <ReactQuill
              style={{height: 350}}
              theme="snow"
              placeholder="Nhập mô tả dự án"
              modules={DEFAULT_QUILL_MODULES}
              formats={DEFAULT_QUILL_FORMAT}
              value={form.getFieldValue('content')}
              onChange={value => form.setFieldValue('content', value)}
            />
          </Form.Item>
        </Col>
      </Row>
      <LinkAndOverviewRowForm type="building" />
    </Fragment>
  );
};
export default InformationForm;

import {Breadcrumb, Divider, Flex, Spin, Table, TableProps, Typography, notification} from 'antd';
import {AxiosError} from 'axios';
import {FC, useEffect, useState} from 'react';
import useWindowSize from 'src/hooks/useWindowSize';
import {IUserInfo} from 'src/services/authentication/type';
import ProfileService from 'src/services/profile';
import {IListUserParams} from 'src/services/profile/type';
import {DEFAULT_NOTI_DURATION} from 'src/utils/common/constants';
import RolePicker from './rolePicker';
import DeleteUserBtn from './deleteUserBtn';
import {CheckCircleFilled, CloseCircleFilled} from '@ant-design/icons';
import ActivateUserBtn from './activateUserBtn';
//
const {Title} = Typography;
const profileService = new ProfileService();
const Decentralization: FC = () => {
  const {commonTableHeight, isMobileMode} = useWindowSize();
  //
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<IUserInfo[]>([]);
  const [tableParams, setTableParams] = useState<IListUserParams>({
    page: 1,
    results: 10,
    sortField: 'createdAt',
    sortOrder: 'descend',
  });
  const [total, setTotal] = useState<number>(0);
  const [reload, setReload] = useState<boolean>(false);
  //
  const onReload = () => setReload(prev => !prev);

  const columns: TableProps<IUserInfo>['columns'] = [
    {
      title: 'Tên người dùng',
      dataIndex: 'displayName',
      key: 'displayName',
    },
    {
      title: 'Xác minh SĐT',
      dataIndex: 'active',
      key: 'active',
      align: 'center',
      render: (_, record) =>
        record.active ? (
          <CheckCircleFilled style={{color: 'green', fontSize: 14}} />
        ) : (
          <CloseCircleFilled style={{color: 'red', fontSize: 14}} />
        ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Điện thoại',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: 'Vai trò',
      dataIndex: 'role',
      key: 'role',
      render: (_, record) => <RolePicker user={record} />,
    },
    {
      title: 'Thao tác',
      fixed: isMobileMode ? 'right' : undefined,
      dataIndex: 'function',
      key: 'function',
      render: (_, record) => (
        <Flex align="center">
          <ActivateUserBtn user={record} onReload={onReload} />
          <Divider orientation="center" type="vertical" />
          <DeleteUserBtn user={record} onReload={onReload} />
        </Flex>
      ),
    },
  ];
  //
  useEffect(() => {
    getListUser();
  }, [tableParams.page, tableParams.results, reload]);
  //

  const getListUser = async () => {
    setLoading(true);
    try {
      let res = await profileService.listUser(tableParams);
      if (res && res.code === 200) {
        setData(res.data.list);
        setTotal(res.data.total);
      }
    } catch (err) {
      if (err instanceof AxiosError) {
        notification.error({
          message: 'Có lỗi xảy ra',
          description: err.response?.data.message,
          duration: DEFAULT_NOTI_DURATION,
        });
      }
    }
    setLoading(false);
  };
  const handleTableChange: TableProps['onChange'] = pagination => {
    if (pagination && pagination.current && pagination.pageSize) {
      setTableParams({
        page: pagination.current,
        results: pagination.pageSize,
      });

      // `dataSource` is useless since `pageSize` changed
      if (pagination.pageSize !== tableParams?.results) {
        setData([]);
      }
    }
  };
  //
  return (
    <div>
      <Title level={3}>Phân quyền</Title>
      <Breadcrumb>
        <Breadcrumb.Item href="/">Trang chủ</Breadcrumb.Item>
        <Breadcrumb.Item>Phân quyền</Breadcrumb.Item>
      </Breadcrumb>
      <div style={{marginTop: 15}}>
        <Table
          columns={columns}
          dataSource={data}
          loading={loading}
          rowKey={record => record._id}
          pagination={{total, current: tableParams.page, pageSize: tableParams.results}}
          onChange={handleTableChange}
          scroll={{y: commonTableHeight}}
        />
      </div>
    </div>
  );
};
export default Decentralization;

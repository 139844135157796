import {Button, Flex, Form, FormProps, Input, Typography, notification} from 'antd';
import {FC, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {ROUTE_CONFIG} from 'src/routes/config';
import AuthenService from 'src/services/authentication';
import {ISignUpPayload} from 'src/services/authentication/type';
import {showNotiErr} from 'src/utils/notification';
import {validateConfirmPassword, validateEmail, validatePhoneNumber} from '../Authen.utils';
//
const authenService = new AuthenService();
const {Text} = Typography;
//
const SignUpScreen: FC = () => {
  const navigate = useNavigate();
  //
  const [loading, setLoading] = useState<boolean>(false);

  const onFinish: FormProps<ISignUpPayload>['onFinish'] = async (values: ISignUpPayload) => {
    setLoading(true);
    authenService
      .signUp(values)
      .then(res => {
        notification.success({
          message: 'Đăng ký thành công',
          description: res.message,
        });
        navigate(ROUTE_CONFIG.SIGNIN);
      })
      .catch(err => {
        showNotiErr(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <Flex vertical align="center" gap="small">
      <Form
        name="signup_form"
        labelCol={{span: 24}}
        wrapperCol={{span: 24}}
        initialValues={{
          email: '',
          password: '',
        }}
        autoComplete="off"
        onFinish={onFinish}>
        <Form.Item<ISignUpPayload>
          label="Tên hiển thị"
          name="displayName"
          rules={[{required: true, message: 'Trường này là bắt buộc!'}]}>
          <Input placeholder="Nhập tên hiển thị" />
        </Form.Item>
        <Form.Item<ISignUpPayload>
          label="Số điện thoại"
          name="phoneNumber"
          rules={[{required: true, message: 'Trường này là bắt buộc!'}, validatePhoneNumber]}>
          <Input placeholder="Nhập số điện thoại" />
        </Form.Item>
        <Form.Item<ISignUpPayload>
          label="Email"
          name="email"
          rules={[{required: true, message: 'Email là bắt buộc!'}, validateEmail]}>
          <Input placeholder="Nhập email" />
        </Form.Item>
        <Form.Item<ISignUpPayload>
          label="Mật khẩu"
          name="password"
          rules={[{required: true, message: 'Mật khẩu là bắt buộc!'}]}>
          <Input.Password placeholder="Nhập mật khẩu" />
        </Form.Item>
        <Form.Item<ISignUpPayload>
          label="Xác nhận mật khẩu"
          name="confirmPassword"
          dependencies={['password']}
          rules={[{required: true, message: 'Trường này là bắt buộc'}, validateConfirmPassword]}>
          <Input.Password placeholder="Nhập xác nhận mật khẩu" />
        </Form.Item>
        <Button loading={loading} type="primary" htmlType="submit" block>
          Đăng ký
        </Button>
      </Form>
      <Text>
        Bạn đã có tài khoản? <Link to="/signIn">Đăng nhập</Link>
      </Text>
    </Flex>
  );
};
export default SignUpScreen;

import http, {IBaseHTTPResponse} from 'src/config/http';
import {STOCK_ENDPOINT} from './endpoint';
import {
  ICrudStocResponse,
  IHTMLResponse,
  IImportProjectParams,
  IImportStockResponse,
  IListStockParams,
  IListStockResponse,
  ISendClickEventPayload,
  IStockDetailResponse,
  IStockInfo,
} from './type';

export default class StockService {
  public async listStock(params: IListStockParams) {
    const {data} = await http.get<IListStockResponse>(STOCK_ENDPOINT.common, {params});
    return data;
  }
  public async updateStock(id: string, payload: {canRead: string[]}) {
    const {data} = await http.post<ICrudStocResponse>(`${STOCK_ENDPOINT.common}/${id}`, payload);
    return data;
  }
  public async updateStockByProperty(id: string, payload: IStockInfo) {
    const {data} = await http.post<ICrudStocResponse>(`${STOCK_ENDPOINT.common}/${id}/properties`, payload);
    return data;
  }
  public async renderHTMLJSON(id: string) {
    const {data} = await http.get<IHTMLResponse>(`${STOCK_ENDPOINT.renderHTMLJSON}/${id}`);
    return data;
  }
  public async importStock(params: IImportProjectParams, body: FormData) {
    const {data} = await http.post<IImportStockResponse>(STOCK_ENDPOINT.import, body, {
      params,
      headers: {'Content-Type': 'application/vnd.ms-excel'},
    });
    return data;
  }
  public async renderHTMLById(fileId: string) {
    const {data} = await http.get<string>(`${STOCK_ENDPOINT.renderHTML}/${fileId}`);
    return data;
  }
  public async getDetail(id: string) {
    const {data} = await http.get<IStockDetailResponse>(`${STOCK_ENDPOINT.common}/${id}`);
    return data;
  }

  public async getDetailByCode(projectId: string, stockCode: string) {
    const {data} = await http.get<IStockDetailResponse>(`${STOCK_ENDPOINT.getDetailCode}/${projectId}/${stockCode}`);
    return data;
  }
  public async sendClickEvent(projectId: string, stockCode: string, payload: ISendClickEventPayload) {
    const {data} = await http.post<IBaseHTTPResponse>(
      `${STOCK_ENDPOINT.common}/code/${projectId}/${stockCode}/event`,
      payload,
    );
    return data;
  }
}

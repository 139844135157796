import { CheckOutlined } from '@ant-design/icons';
import { Col, List, Row, Typography } from 'antd';
import { FC, Fragment } from 'react';
import { Outlet } from 'react-router-dom';
import useWindowSize from 'src/hooks/useWindowSize';
import './styles.scss';
//
const {Text} = Typography;

//
const AuthenLayoutComponent: FC = () => {
  const {isMobileMode} = useWindowSize();
  const hightlightFeatures: string[] = [
    'Tìm kiếm căn hộ.',
    'Bảng giá chi tiết và tính giá tự động.',
    'Cập nhật tình trạng căn hộ.',
    'Tài nguyên thông tin layout, mặt bằng,....',
  ];
  return (
    <div className="body-container">
      <div className="authen-form-container">
        <Row gutter={30}>
          <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
            <div className="authen-header-container">
              <img src={require('src/assets/logo/logo.jpg')} className="logo-container" alt="Đông Đô Land logo" />
              {isMobileMode ? (
                <></>
              ) : (
                <Fragment>
                  <Text>Hệ thống hỗ trợ dành cho</Text>
                  <Text>Môi giới bất động sản Đông Đô Land</Text>
                </Fragment>
              )}
            </div>
            {isMobileMode ? (
              <></>
            ) : (
              <List
                size="small"
                header={<></>}
                footer={<></>}
                dataSource={hightlightFeatures}
                renderItem={item => (
                  <List.Item>
                    <CheckOutlined /> {item}
                  </List.Item>
                )}
              />
            )}
          </Col>
          <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
            <Outlet />
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default AuthenLayoutComponent;

import {Card, List, Typography} from 'antd';
import {FC} from 'react';
import {IDashboardProjectInfo} from '../../type';
import {ROUTE_CONFIG} from 'src/routes/config';
//
interface IBuildingCardProps {
  project: IDashboardProjectInfo;
}
const {Link} = Typography;
//
const BuildingListCard: FC<IBuildingCardProps> = props => {
  const {project} = props;
  return (
    <Card title={`Bảng hàng ${project.name}`} styles={{body: {paddingBlockStart: 0}}}>
      <List
        size="small"
        dataSource={project.buildingList}
        renderItem={item => (
          <List.Item>
            <List.Item.Meta
              title={
                <Link href={`${ROUTE_CONFIG.BUILDING_INFO}/${item._id}`}>
                  {item.block ? `${item.block?.name} -${item.name}` : item.name}
                </Link>
              }
            />
          </List.Item>
        )}
      />
    </Card>
  );
};
export default BuildingListCard;

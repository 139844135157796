import http, {IBaseHTTPResponse} from 'src/config/http';
import {BLOCK_ENDPOINT} from './endpoint';
import {ICRUDBlockPayload, ICRUDBlockResponse, IListBlockResponse} from './type';

export default class BlockService {
  public async listBlock() {
    const {data} = await http.get<IListBlockResponse>(BLOCK_ENDPOINT.common);
    return data;
  }
  public async createBlock(payload: ICRUDBlockPayload) {
    const {data} = await http.put<ICRUDBlockResponse>(BLOCK_ENDPOINT.common, payload);
    return data;
  }
  public async updateBlock(id: string, payload: ICRUDBlockPayload) {
    const {data} = await http.post<ICRUDBlockResponse>(`${BLOCK_ENDPOINT.common}/${id}`, payload);
    return data;
  }
  public async getBlockDetail(id: string) {
    const {data} = await http.get<ICRUDBlockResponse>(`${BLOCK_ENDPOINT.common}/${id}`);
    return data;
  }
  public async deleteBlock(id: string) {
    const {data} = await http.delete<IBaseHTTPResponse>(`${BLOCK_ENDPOINT.common}/${id}`);
    return data;
  }
}

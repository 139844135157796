import {Button, Flex, Form, Input, Space, Typography, notification} from 'antd';
import {FC, useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {IVerifyOTPPayload} from 'src/services/authentication/type';
import {IOTPState} from '../Authen.type';
import AuthenService from 'src/services/authentication';
import {showNotiErr} from 'src/utils/notification';
import {ROUTE_CONFIG} from 'src/routes/config';
import {useAppDispatch} from 'src/stores/utils';
import {setIsLogin, setTokens} from '../store';
//
const {Text, Title} = Typography;
//
const authenService = new AuthenService();
//
const OTPScreen: FC = () => {
  // ROUTER HOOK
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const state: IOTPState = location.state;
  // Form utils
  const [form] = Form.useForm<IVerifyOTPPayload>();
  //
  const [loading, setLoading] = useState<boolean>(false);
  const [retrySec, setRetrySec] = useState<number>(state.retryAfterSeconds);
  //
  const onFinish = async (values: IVerifyOTPPayload) => {
    setLoading(true);
    authenService
      .verifyOTP({code: values.code, requestToken: state.requestToken})
      .then(res => {
        const {data, message} = res;
        const {tokens} = data;
        if (tokens) {
          dispatch(setTokens(tokens));
          dispatch(setIsLogin(true));
        }
        notification.success({message});
        navigate(ROUTE_CONFIG.HOME);
      })
      .catch(err => showNotiErr(err))
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <Flex vertical align="center" gap="small">
      <Title level={3}>Xác nhận OTP</Title>
      <Text>Vui lòng nhập OTP vừa được gửi tới số điện thoại {state.phoneNumber || ''}</Text>
      <Form
        form={form}
        name="otp_form"
        labelCol={{span: 24}}
        wrapperCol={{span: 24}}
        initialValues={{
          code: '',
        }}
        onFinish={onFinish}>
        <Space direction="vertical" size={'small'}>
          <Form.Item<IVerifyOTPPayload>
            label=""
            name="code"
            rules={[{required: true, message: 'Trường này là bắt buộc!'}]}>
            <Input.OTP size="large" variant="filled" status="" />
          </Form.Item>
          <Form.Item noStyle>
            <Button loading={loading} type="primary" htmlType="submit" block>
              Xác thực
            </Button>
          </Form.Item>
        </Space>
      </Form>
      <Text>Gửi lại OTP sau {retrySec} giây</Text>
    </Flex>
  );
};
export default OTPScreen;

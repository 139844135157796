export enum EUserRole {
  GUEST = 'guest',
  SALE_INTERNAL = 'sales-internal',
  SALE_EXTERNAL = 'sales-external',
  ADMIN = 'admin',
  SUPER_ADMIN = 'superadmin',
}
export enum EMultiViews {
  simple = 'simple',
  full = 'full',
}

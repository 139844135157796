import {Empty, Spin} from 'antd';
import parse from 'html-react-parser';
import {FC, Fragment, memo, useEffect, useRef, useState} from 'react';
import BuildingService from 'src/services/building';
import {IBuildingInfo} from 'src/services/building/type';
import StockService from 'src/services/stock';
import {showNotiErr} from 'src/utils/notification';
import DetailModal from './components/detailModal';
import useWindowSize from 'src/hooks/useWindowSize';
import {IProjectInfo} from 'src/services/project/type';
import ProjectService from 'src/services/project';
//
interface IExcelTableProps {
  html: string;
  projectId: string;
  buildingId: string;
  loading?: boolean;
}
const buildingService = new BuildingService();
const stockService = new StockService();
const projectService = new ProjectService();
//
const ExcelTable: FC<IExcelTableProps> = props => {
  const {html = '', projectId, buildingId, loading} = props;
  //
  const {isMobileMode, isLandscape} = useWindowSize();
  const ref = useRef<HTMLDivElement>(null);
  const [selectedStockCode, setSelectedStockCode] = useState<string>('');
  const [selectedCell, setSelectedCell] = useState<HTMLTableCellElement | null>(null);
  //
  const [showStockDetail, setShowStockDetail] = useState<boolean>(false);
  const [buildingDetail, setBuildingDetail] = useState<IBuildingInfo | null>(null);
  const [canSeeStockCodeList, setCanSeeStockCodeList] = useState<string[]>([]);
  const [projectDetail, setProjectDetail] = useState<IProjectInfo | null>(null);
  //
  useEffect(() => {
    getBuildingStockData();
  }, [buildingId]);

  const getBuildingStockData = async () => {
    if (buildingId) {
      buildingService
        .getBuildingDetail(buildingId)
        .then(res => {
          const {data} = res;
          setBuildingDetail(data);
          //
          stockService
            .listStock({
              buildingId: data._id,
              projectId: data.projectId,
              page: 1,
              results: 200,
            })
            .then(res => {
              const {data} = res;
              const {list} = data;
              let stockCodeList: string[] = [];
              list.forEach(item => stockCodeList.push(item.code));
              setCanSeeStockCodeList(stockCodeList);
            })
            .catch(err => showNotiErr(err));
          // Lấy thông tin dự án
          projectService
            .getProjectDetail(data.projectId)
            .then(res => {
              const {data} = res;
              setProjectDetail(data);
            })
            .catch(err => showNotiErr(err));
        })
        .catch(err => showNotiErr(err));
    }
  };
  //
  useEffect(() => {
    if (!ref.current) {
      return;
    } else {
      // SET STYLE
      const div = ref.current.querySelector('div');
      if (div) {
        div.style.overflowX = 'scroll';
      }

      const tables = ref.current.querySelectorAll('table');
      if (tables.length > 0) {
        tables.forEach(table => {
          table.style.width = '-webkit-fill-available';
          table.style.tableLayout = isMobileMode && !isLandscape ? 'auto' : 'fixed';
        });
      }
      // ADD STYLES
      const cellSpan = ref.current.querySelectorAll('span');
      cellSpan.forEach(span => (span.style.fontSize = isMobileMode ? '8px' : '10px'));
      const tableCells = ref.current.querySelectorAll('td');
      const tableRows = ref.current.querySelectorAll('tr');
      tableRows.forEach(row => (row.style.height = '16px'));

      //
      tableCells.forEach(cell => {
        cell.style.fontSize = isMobileMode ? '8px' : '10px';
        cell.style.verticalAlign = 'middle';
        cell.style.letterSpacing = '1px';
        if (isMobileMode && isLandscape) {
          cell.style.whiteSpace = 'nowrap';
          cell.style.textOverflow = 'ellipsis';
        }

        const row = cell.closest('tr');
        const rowCells = row?.querySelectorAll('td');
        if (rowCells && rowCells.length > 0) {
          const firstRowCells = rowCells[0];
          const firstCellContent = firstRowCells.innerHTML;
          if (isMobileMode && !isLandscape) {
            firstRowCells.style.position = 'sticky';
            firstRowCells.style.left = '0';
          }
          if (Number(firstCellContent)) {
            //

            const {cellIndex} = cell;
            const columnIndex = cellIndex - 1;
            // DETECT floor number
            const rowIndex = cell.closest('tr')?.rowIndex || 0;
            const currentRow = tableRows[rowIndex];
            const firstCell = currentRow.querySelector('td');

            const floorNumber = firstCell?.innerHTML || '';
            const savedFloorNumber = floorNumber.length === 1 ? `0${floorNumber}` : floorNumber;

            // DETECT room number
            // Dò các row có tiêu đề là số căn
            let roomCodeRows: any = [];

            tableRows.forEach(row => {
              const cellinRow = row.querySelectorAll('td');
              const firstCell = cellinRow[0];

              if (
                firstCell &&
                (firstCell.innerHTML.includes('Tầng / Căn') ||
                  firstCell.innerHTML.includes('Mã căn') ||
                  firstCell.innerHTML.includes('T/căn') ||
                  firstCell.innerHTML.includes('Căn') ||
                  firstCell.innerHTML.includes('M.Căn') ||
                  firstCell.innerHTML.includes('T.Căn') ||
                  firstCell.innerHTML.includes('Tầng/Căn') ||
                  firstCell.innerHTML.includes('TẦNG/CĂN'))
              ) {
                roomCodeRows.push(row);
              }
            });
            // Sau đó xem row nào có index gần nhất với cái row hiện tại
            const nearestRoomRow = roomCodeRows.reduce((prev: any, curr: any) => {
              if (prev.rowIndex < rowIndex && rowIndex < curr.rowIndex) {
                return prev;
              } else if (prev.rowIndex < curr.rowIndex && curr.rowIndex < rowIndex) {
                return curr;
              }
            });
            const roomCells = nearestRoomRow ? nearestRoomRow.querySelectorAll('td') : [];

            // Check xem ô số phòng đằng trước có bị trống và có phải là td có colspan ko
            // Nếu có colspan thì phải + 1 vào columnIndex, ko thì sẽ lấy sai ô số phòng
            const previousRoomCodeCell = roomCells[columnIndex - 1];
            const colSpanValue = previousRoomCodeCell?.getAttribute('colspan');
            const roomCodeCell = colSpanValue ? undefined : roomCells[columnIndex];
            const roomCode = roomCodeCell ? roomCodeCell.innerHTML : '';
            const savedRoomCode = roomCode.length === 1 ? `0${roomCode}` : roomCode;
            const stockCode = buildingDetail?.shortName + '.' + savedFloorNumber + savedRoomCode;
            if (columnIndex !== 0 && savedFloorNumber && savedRoomCode) {
              cell.style.cursor = 'pointer';
              cell.addEventListener('click', e => {
                handleShowStockDetail(e, cell, stockCode);
              });
            }
          }
        }
      });
      return () => {
        tableCells.forEach(cell =>
          cell.removeEventListener('click', e => {
            handleShowStockDetail(e, null, '');
          }),
        );
      };
    }
  }, [html, buildingDetail, canSeeStockCodeList, isLandscape]);
  //

  const handleShowStockDetail = (e: MouseEvent, cell: HTMLTableCellElement | null, stockCode: string) => {
    setShowStockDetail(true);
    e.preventDefault();
    if (cell) {
      setSelectedCell(cell);
      setSelectedStockCode(stockCode);
    }
  };
  const handleHideStockDetail = () => {
    setShowStockDetail(false);
    setSelectedStockCode('');
    setSelectedCell(null);
  };
  if (html) {
    return (
      <Fragment>
        {loading && <Spin fullscreen />}
        <div ref={ref}>{parse(html)}</div>
        <DetailModal
          selectedCell={selectedCell}
          show={showStockDetail}
          onHide={handleHideStockDetail}
          projectId={projectId}
          selectedStockCode={selectedStockCode}
          buildingDetail={buildingDetail}
          projectDetail={projectDetail}
        />
      </Fragment>
    );
  } else {
    return <Empty />;
  }
};
export default memo(ExcelTable);

import {notification} from 'antd';
import {AxiosError} from 'axios';

//
export const showNotiErr = (err: any, customMessage?: string) => {
  if (err instanceof AxiosError) {
    notification.error({
      message: customMessage || 'Có lỗi xảy ra',
      description: err.response?.data.message,
    });
  }
};

import {ConfigProvider, Alert, Spin} from 'antd';
import locale from 'antd/locale/vi_VN';
import {Route, Routes} from 'react-router-dom';
import AuthenLayoutComponent from './components/authenLayout';
import LayoutComponent from './components/layout';
import {AUTHEN_ROUTES, MAIN_ROUTES} from './routes';
import {COMPONENT_TOKEN, GLOBAL_TOKEN} from './styles/themeToken';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';
import store, {persistor} from './stores';
import './styles/custom.scss'
//
const {ErrorBoundary} = Alert;
//
function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={<Spin />}>
        <ErrorBoundary>
          <ConfigProvider
            locale={locale}
            theme={{
              components: COMPONENT_TOKEN,
              token: GLOBAL_TOKEN,
            }}>
            <Routes>
              <Route element={<AuthenLayoutComponent />}>
                {AUTHEN_ROUTES.map((item, index) => (
                  <Route path={item.path} element={item.element} key={index} />
                ))}
              </Route>
              <Route element={<LayoutComponent />}>
                {MAIN_ROUTES.map((item, index) => (
                  <Route path={item.path} element={item.element} key={index} />
                ))}
              </Route>
            </Routes>
          </ConfigProvider>
        </ErrorBoundary>
      </PersistGate>
    </Provider>
  );
}

export default App;

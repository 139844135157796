import {Button, Form, Input, notification} from 'antd';
import {AxiosError} from 'axios';
import {FC, useState} from 'react';
import AccountService from 'src/services/account';
import {IUpdatePasswordPayload} from 'src/services/account/type';
import {DEFAULT_NOTI_DURATION} from 'src/utils/common/constants';
//

const accountService = new AccountService();
//
const ChangePassword: FC = () => {
  const [form] = Form.useForm<IUpdatePasswordPayload>();
  const [loading, setLoading] = useState<boolean>(false);

  //
  const onFinish = async (values: IUpdatePasswordPayload) => {
    setLoading(true);
    try {
      let res = await accountService.changePassword({
        newPassword: values.newPassword,
      });
      if (res && res.code === 200) {
        notification.success({
          message: 'Cập nhật thành công',
          description: res.message,
          duration: DEFAULT_NOTI_DURATION,
        });
      }
    } catch (err) {
      if (err instanceof AxiosError) {
        notification.error({
          message: 'Có lỗi xảy ra',
          description: err.response?.data.message,
          duration: DEFAULT_NOTI_DURATION,
        });
      }
    }
    setLoading(false);
  };
  return (
    <div className="form-container">
      <Form
        form={form}
        initialValues={{
          password: '',
          newPassword: '',
        }}
        name="profile_info_form"
        labelCol={{span: 8}}
        wrapperCol={{span: 16}}
        labelAlign="left"
        colon={false}
        onFinish={onFinish}>
        <Form.Item<IUpdatePasswordPayload> label="MẬT KHẨU MỚI" name="newPassword">
          <Input.Password placeholder="Nhập mật khẩu mới" />
        </Form.Item>
        <Form.Item<IUpdatePasswordPayload> label=" ">
          <Button loading={loading} type="primary" htmlType="submit" block>
            Lưu thay đổi
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
export default ChangePassword;

import http, {IBaseHTTPResponse} from 'src/config/http';
import {BUILDING_ENDPOINT} from './endpoint';
import {ICRUDBuildingResponse, ICRUDBuildingPayload, IListBuildingParams, IListBuildingResponse} from './type';

export default class BuildingService {
  public async listBuilding(params: IListBuildingParams) {
    const {data} = await http.get<IListBuildingResponse>(BUILDING_ENDPOINT.common, {params});
    return data;
  }
  public async getBuildingDetail(id: string) {
    const {data} = await http.get<ICRUDBuildingResponse>(`${BUILDING_ENDPOINT.common}/${id}`);
    return data;
  }
  public async createBuilding(payload: ICRUDBuildingPayload) {
    const {data} = await http.put<ICRUDBuildingResponse>(BUILDING_ENDPOINT.common, payload);
    return data;
  }
  public async updateBuilding(id: string, payload: ICRUDBuildingPayload) {
    const {data} = await http.post<ICRUDBuildingResponse>(`${BUILDING_ENDPOINT.common}/${id}`, payload);
    return data;
  }
  public async deleteBuilding(id: string) {
    const {data} = await http.delete<IBaseHTTPResponse>(`${BUILDING_ENDPOINT.common}/${id}`);
    return data;
  }
}

import {
  CheckCircleOutlined,
  DeleteOutlined,
  PlusOutlined,
  RightOutlined,
  UserAddOutlined,
  UserOutlined,
} from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Dropdown,
  Flex,
  List,
  Popconfirm,
  Row,
  Spin,
  Tag,
  Tooltip,
  Typography,
  notification,
} from 'antd';
import {FC, Fragment, useCallback, useEffect, useState} from 'react';
import {useParams} from 'react-router';
import {useNavigate} from 'react-router-dom';
import HTMLParser from 'src/components/common/htmllParser';
import {ROUTE_CONFIG} from 'src/routes/config';
import ScreenParams from 'src/routes/screenParams';
import {IUserInfo} from 'src/services/authentication/type';
import BlockService from 'src/services/block';
import {IBlockInfo} from 'src/services/block/type';
import BuildingService from 'src/services/building';
import {IBuildingInfo} from 'src/services/building/type';
import ProfileService from 'src/services/profile';
import ProjectService from 'src/services/project';
import {IProjectInfo} from 'src/services/project/type';
import {toggleReloadProject} from 'src/stores/common/layout.slice';
import {useAppDispatch} from 'src/stores/utils';
import {EUserRole} from 'src/utils/common/enum';
import {checkIsAdmin, checkIsSuperAdmin} from 'src/utils/common/function';
import {showNotiErr} from 'src/utils/notification';
//
const {Title} = Typography;
const projectService = new ProjectService();
const blockService = new BlockService();
const buildingService = new BuildingService();
const profileService = new ProfileService();
//
const ProjectInfo: FC = () => {
  const {id} = useParams<ScreenParams['PROJECT_INFO']>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  //
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
  const [projectDetail, setProjectDetail] = useState<IProjectInfo | null>(null);
  const [blockList, setBlockList] = useState<IBlockInfo[]>([]);
  const [listBuilding, setListBuilding] = useState<IBuildingInfo[]>([]);
  const [listAdmin, setListAdmin] = useState<{_id: number; displayName: string}[]>([]);
  const [listUser, setListUser] = useState<IUserInfo[]>([]);
  const [loadingAddUser, setLoadingAddUser] = useState<boolean>(false);
  const [loadingDeleteUser, setLoadingDeleteUser] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);
  //
  useEffect(() => {
    getProjectInfo();
    getBlockList();
  }, [id, reload]);

  useEffect(() => {
    getListUser();
  }, [listAdmin]);
  //
  const toggleReload = () => {
    setReload(prev => !prev);
  };
  const getProjectInfo = async () => {
    if (id) {
      if (projectDetail) {
        setLoading(true);
      }
      projectService
        .getProjectDetail(id)
        .then(res => {
          const {data} = res;
          setProjectDetail(data);
          projectService
            .listAdminByProject(id)
            .then(res => {
              const {data} = res;
              setListAdmin(data);
            })
            .catch(err => showNotiErr(err));
        })
        .catch(err => showNotiErr(err))
        .finally(() => setLoading(false));
    }
  };
  const getListUser = async () => {
    if (checkIsSuperAdmin()) {
      let payload = {page: 1, results: 100, sortField: 'createdAt', sortOrder: 'descend', role: EUserRole.ADMIN};
      profileService
        .listUser(payload)
        .then(res => {
          const {data} = res;
          const {list} = data;

          if (listAdmin.length > 0) {
            const listAdminRemain = list.filter(user => listAdmin.every(item => item._id !== user._id));
            setListUser(listAdminRemain);
          } else {
            setListUser(list);
          }
        })
        .catch(err => showNotiErr(err));
    } else return;
  };
  const getBlockList = async () => {
    if (id) {
      blockService
        .listBlock()
        .then(res => {
          const {data} = res;
          const {list} = data;
          const blockOfThisProject = list.filter(item => item.projectId === id);
          if (blockOfThisProject.length > 0) {
            setBlockList(blockOfThisProject);
          } else {
            setBlockList([]);
            getListBuilding();
          }
        })
        .catch(err => showNotiErr(err));
    }
  };
  const getListBuilding = async () => {
    if (id) {
      setLoading(true);
      buildingService
        .listBuilding({
          page: 1,
          results: 50,
        })
        .then(res => {
          const {data} = res;
          const {list} = data;
          const buildingsInThisBlock = list.filter(item => item.projectId && item.projectId === id);
          setListBuilding(buildingsInThisBlock);
        })
        .catch(err => showNotiErr(err))
        .finally(() => setLoading(false));
    }
  };
  //
  const handleNavigateUpdate = useCallback(() => {
    if (projectDetail && projectDetail._id) {
      navigate(`${ROUTE_CONFIG.UDPATE_PROJECT}/${projectDetail._id}`);
    }
  }, [projectDetail]);
  //
  const handleNavigateAddBlock = useCallback(() => {
    if (projectDetail && projectDetail._id) {
      navigate(`${ROUTE_CONFIG.ADD_BLOCK}/${projectDetail._id}`);
    }
  }, [projectDetail]);
  //
  const handleNavigateBlockInfo = useCallback(
    (blockId: string) => {
      if (blockId) {
        navigate(`${ROUTE_CONFIG.BLOCK_INFO}/${blockId}`);
      }
    },
    [projectDetail],
  );
  //
  const handleDeleteProject = useCallback(async () => {
    if (projectDetail && projectDetail._id) {
      setLoadingDelete(true);
      projectService
        .deleteProject(projectDetail._id)
        .then(res => {
          const {message} = res;
          notification.success({
            message,
          });
          dispatch(toggleReloadProject());
          navigate(ROUTE_CONFIG.HOME);
        })
        .catch(err => showNotiErr(err))
        .finally(() => setLoadingDelete(false));
    } else return;
  }, [projectDetail]);
  //
  const handlePutAdminToProject = async (userId: number) => {
    setLoadingAddUser(true);
    projectService
      .putAdminToProject(id || '', userId)
      .then(res => {
        const {message} = res;
        notification.success({message});
        toggleReload();
      })
      .catch(err => showNotiErr(err))
      .finally(() => {
        setLoadingAddUser(false);
      });
  };
  const handleRemoveAdminFromProject = async (userId: number) => {
    setLoadingDeleteUser(true);
    projectService
      .removeAdminFromProject(id || '', userId)
      .then(res => {
        const {message} = res;
        notification.success({message});
        toggleReload();
      })
      .catch(err => showNotiErr(err))
      .finally(() => {
        setLoadingDeleteUser(false);
      });
  };
  return (
    <Fragment>
      <Row gutter={16}>
        <Col xxl={6} xl={6} lg={6} md={6} sm={24} xs={24} span={6}>
          {checkIsSuperAdmin() && (
            <Card
              style={{marginBottom: 15}}
              title={
                <Flex justify="space-between" align="center">
                  <Title level={5}>Danh sách Admin</Title>
                  <Dropdown
                    trigger={['click']}
                    menu={{
                      items: listUser.map(item => ({
                        key: item._id,
                        label: item.displayName,
                        icon: <UserOutlined />,
                        onClick: () => handlePutAdminToProject(item._id),
                      })),
                    }}>
                    <Button loading={loadingAddUser} type="text" shape="circle" icon={<UserAddOutlined />} />
                  </Dropdown>
                </Flex>
              }
              styles={{body: {paddingBlockStart: 0}}}>
              <List
                size="small"
                itemLayout="horizontal"
                dataSource={listAdmin}
                renderItem={(item, index) => (
                  <List.Item
                    actions={[
                      <Button
                        loading={loadingDeleteUser}
                        onClick={() => handleRemoveAdminFromProject(item._id)}
                        size="small"
                        icon={<DeleteOutlined />}
                        type="text"
                        danger
                      />,
                    ]}>
                    <List.Item.Meta avatar={<UserOutlined />} title={item.displayName} />
                  </List.Item>
                )}
              />
            </Card>
          )}
          {projectDetail && projectDetail.overviews ? (
            <Card style={{marginBottom: 16}} title="Tổng quan" styles={{body: {paddingBlockStart: 0}}}>
              <List
                size="small"
                itemLayout="horizontal"
                dataSource={projectDetail.overviews}
                renderItem={(item, index) => (
                  <List.Item>
                    <List.Item.Meta avatar={<CheckCircleOutlined />} title={item.title} description={item.value} />
                  </List.Item>
                )}
              />
            </Card>
          ) : (
            <></>
          )}

          {blockList.length > 0 ? (
            <Card
              style={{marginBottom: 15}}
              title={
                <Flex justify="space-between" align="center">
                  <Title level={5}>Phân khu</Title>
                  {(checkIsAdmin() || checkIsSuperAdmin()) && (
                    <Tooltip title="Thêm phân khu" trigger="hover">
                      <Button onClick={handleNavigateAddBlock} type="text" shape="circle" icon={<PlusOutlined />} />
                    </Tooltip>
                  )}
                </Flex>
              }
              styles={{body: {paddingBlockStart: 0}}}>
              <List
                size="small"
                itemLayout="horizontal"
                dataSource={blockList}
                renderItem={item => (
                  <List.Item
                    key={item._id}
                    style={{cursor: 'pointer'}}
                    onClick={() => handleNavigateBlockInfo(item._id)}>
                    <List.Item.Meta avatar={<RightOutlined />} title={item.name} />
                  </List.Item>
                )}
              />
            </Card>
          ) : listBuilding.length > 0 ? (
            <Card style={{marginBottom: 15}} title="Toà nhà" styles={{body: {paddingBlockStart: 0}}}>
              <List
                size="small"
                itemLayout="horizontal"
                dataSource={listBuilding}
                renderItem={(item, index) => (
                  <List.Item onClick={() => navigate(`${ROUTE_CONFIG.BUILDING_INFO}/${item?._id}`)}>
                    <List.Item.Meta avatar={<RightOutlined />} title={item.name} />
                  </List.Item>
                )}
              />
            </Card>
          ) : (
            <></>
          )}
        </Col>

        <Col xxl={18} xl={18} lg={18} md={18} sm={24} xs={24} span={18}>
          <Card
            styles={{body: {paddingBlockStart: 0}}}
            title={
              <Flex justify="space-between" align="center" wrap="wrap">
                <div>
                  <Flex gap={8} align="center" wrap="wrap">
                    <Title level={3}>{projectDetail?.name}</Title>
                    {projectDetail?.isPrivate ? <Tag>Tạm ẩn</Tag> : <></>}
                  </Flex>
                </div>

                {checkIsSuperAdmin() && (
                  <Flex align="center" gap={5}>
                    <Button onClick={handleNavigateUpdate} type="primary">
                      Chỉnh sửa thông tin
                    </Button>
                    <Popconfirm
                      title="Xác nhận xoá dự án"
                      description="Bạn chắc chắn muốn xoá dự án này?"
                      onConfirm={handleDeleteProject}
                      okButtonProps={{loading: loadingDelete}}
                      okText="Đồng ý"
                      cancelText="Huỷ">
                      <Button type="dashed" danger>
                        Xoá dự án
                      </Button>
                    </Popconfirm>
                  </Flex>
                )}
              </Flex>
            }>
            {projectDetail && projectDetail.links ? (
              <Flex align="center" gap={5} style={{marginBottom: 15}}>
                {projectDetail.links.map((item, index) => (
                  <Tag
                    key={index}
                    color="blue"
                    style={{cursor: 'pointer'}}
                    onClick={() => window.open(item.value, '_blank')}>
                    {item.title}
                  </Tag>
                ))}
              </Flex>
            ) : (
              <></>
            )}
            <HTMLParser content={projectDetail?.description} />
          </Card>
        </Col>
      </Row>
      {loading && <Spin size="large" />}
    </Fragment>
  );
};
export default ProjectInfo;

import http, { IBaseHTTPResponse } from 'src/config/http';
import { PROJECT_ENDPOINT } from './endpoint';
import {
  ICRUDProjectPayload,
  ICRUDProjectResponse,
  IColorInfo,
  IListColorResponse,
  IListProjectAdminResponse,
  IListProjectByAdminParams,
  IListProjectParams,
  IListProjectResponse
} from './type';

export default class ProjectService {
  public async listProject(params: IListProjectParams) {
    const {data} = await http.get<IListProjectResponse>(PROJECT_ENDPOINT.common, {params});
    return data;
  }
  public async getProjectDetail(id: string) {
    const {data} = await http.get<ICRUDProjectResponse>(`${PROJECT_ENDPOINT.common}/${id}`);
    return data;
  }
  public async createProject(payload: ICRUDProjectPayload) {
    const {data} = await http.put<ICRUDProjectResponse>(PROJECT_ENDPOINT.common, payload);
    return data;
  }
  public async updateProject(id: string, payload: ICRUDProjectPayload) {
    const {data} = await http.post<ICRUDProjectResponse>(`${PROJECT_ENDPOINT.common}/${id}`, payload);
    return data;
  }
  public async deleteProject(id: string) {
    const {data} = await http.delete<IBaseHTTPResponse>(`${PROJECT_ENDPOINT.common}/${id}`);
    return data;
  }
  public async listColor(projectId: string) {
    const {data} = await http.get<IListColorResponse>(`${PROJECT_ENDPOINT.common}/${projectId}/status`);
    return data;
  }
  public async addColor(projectId: string, payload: IColorInfo) {
    const {data} = await http.put<IBaseHTTPResponse>(`${PROJECT_ENDPOINT.common}/${projectId}/status`, payload);
    return data;
  }
  public async updateColor(projectId: string, status: number, payload: IColorInfo) {
    const {data} = await http.post<IBaseHTTPResponse>(
      `${PROJECT_ENDPOINT.common}/${projectId}/status/${status}`,
      payload,
    );
    return data;
  }
  public async deleteColor(projectId: string, status: number) {
    const {data} = await http.delete<IBaseHTTPResponse>(`${PROJECT_ENDPOINT.common}/${projectId}/status/${status}`);
    return data;
  }
  public async listByMyAdmin(params: IListProjectByAdminParams) {
    const {data} = await http.get<IListProjectResponse>(`${PROJECT_ENDPOINT.list_after_permission}`, {params});
    return data;
  }
  public async listAdminByProject(projectId: string) {
    const {data} = await http.get<IListProjectAdminResponse>(`${PROJECT_ENDPOINT.common}/${projectId}/admins`);
    return data;
  }
  public async putAdminToProject(projectId: string, userId: number) {
    const {data} = await http.put<IBaseHTTPResponse>(`${PROJECT_ENDPOINT.common}/${projectId}/admins/${userId}`);
    return data;
  }
  public async removeAdminFromProject(projectId: string, userId: number) {
    const {data} = await http.delete<IBaseHTTPResponse>(`${PROJECT_ENDPOINT.common}/${projectId}/admins/${userId}`);
    return data;
  }
}

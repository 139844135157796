import { Breadcrumb, Button, Col, Flex, Form, Input, Row, Select, Typography, notification } from 'antd';
import { FC, useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import { ICRUDBuildingPayload } from 'src/services/building/type';
//
import 'react-quill/dist/quill.snow.css';
import { useParams } from 'react-router-dom';
import LinkAndOverviewRowForm from 'src/components/common/addLinksAndOverview';
import { ROUTE_CONFIG } from 'src/routes/config';
import ScreenParams from 'src/routes/screenParams';
import BlockService from 'src/services/block';
import { IBlockInfo } from 'src/services/block/type';
import BuildingService from 'src/services/building';
import ProjectService from 'src/services/project';
import { IProjectInfo } from 'src/services/project/type';
import { toggleReloadProject } from 'src/stores/common/layout.slice';
import { useAppDispatch } from 'src/stores/utils';
import { DEFAULT_NOTI_DURATION, DEFAULT_QUILL_FORMAT, DEFAULT_QUILL_MODULES } from 'src/utils/common/constants';
import { showNotiErr } from 'src/utils/notification';
import '../styles.scss';
//
const {Title} = Typography;
const buildingService = new BuildingService();
const projectService = new ProjectService();
const blockService = new BlockService();
//
const AddBuildingScreen: FC = () => {
  const {projectId, blockId} = useParams<ScreenParams['ADD_BUILDING']>();

  const dispatch = useAppDispatch();
  //
  const [form] = Form.useForm<ICRUDBuildingPayload>();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingProject, setLoadingProject] = useState<boolean>(false);
  const [loadingBlock, setLoadingBlock] = useState<boolean>(false);
  const [listProject, setListProject] = useState<IProjectInfo[]>([]);
  const [listBlock, setListBlock] = useState<IBlockInfo[]>([]);
  const [projectDetail, setProjectDetail] = useState<IProjectInfo | null>(null);
  const [blockDetail, setBlockDetail] = useState<IBlockInfo | null>(null);
  //
  useEffect(() => {
    getProjectInfo();
  }, [projectId]);
  useEffect(() => {
    getBlockInfo();
  }, [blockId]);
  //
  const getProjectInfo = async () => {
    if (projectId && projectId !== 'null') {
      projectService
        .getProjectDetail(projectId)
        .then(res => {
          const {data} = res;
          setProjectDetail(data);
        })
        .catch(err => showNotiErr(err));
    } else {
      setProjectDetail(null);
    }
  };
  const getBlockInfo = async () => {
    if (blockId && blockId !== 'null') {
      blockService
        .getBlockDetail(blockId)
        .then(res => {
          const {data} = res;
          setBlockDetail(data);
        })
        .catch(err => showNotiErr(err));
    } else {
      setProjectDetail(null);
    }
  };
  //
  const onFinish = async (values: ICRUDBuildingPayload) => {
    setLoading(true);
    if (projectId && projectId !== 'null') {
      values.projectId = projectId;
    }
    if (blockId && blockId !== 'null') {
      values.blockId = blockId;
    }
    buildingService
      .createBuilding(values)
      .then(res => {
        notification.success({
          message: res.message,
          duration: DEFAULT_NOTI_DURATION,
        });
        dispatch(toggleReloadProject());
        form.resetFields();
      })
      .catch(err => showNotiErr(err))
      .finally(() => setLoading(false));
  };
  //
  const getListProjects = async () => {
    setLoadingProject(true);
    projectService
      .listProject({
        page: 1,
        results: 50,
        sortField: 'createdAt',
        sortOrder: 'descend',
      })
      .then(res => {
        const {data} = res;
        const {list} = data;
        setListProject(list);
      })
      .catch(err => showNotiErr(err))
      .finally(() => setLoadingProject(false));
  };
  const getListBlock = async () => {
    const formProjectId = form.getFieldValue('projectId');
    let idProject = projectId || formProjectId;

    if (idProject) {
      setLoadingBlock(true);
      blockService
        .listBlock()
        .then(res => {
          const {data} = res;
          const {list} = data;

          const blockList = list.filter(item => item.projectId && item.projectId === idProject);
          setListBlock(blockList);
        })
        .catch(err => showNotiErr(err))
        .finally(() => setLoadingBlock(false));
    } else return;
  };
  return (
    <Form form={form} labelCol={{span: 24}} wrapperCol={{span: 24}} autoComplete="off" onFinish={onFinish}>
      <Flex justify="space-between" align="flex-end">
        <div>
          <Title level={3}>Thêm toà nhà</Title>
          <Breadcrumb>
            <Breadcrumb.Item href="/">Trang chủ</Breadcrumb.Item>
            <Breadcrumb.Item href={`${ROUTE_CONFIG.PROJECT_INFO}/${projectId}`}>{projectDetail?.name}</Breadcrumb.Item>
            <Breadcrumb.Item href={`${ROUTE_CONFIG.BLOCK_INFO}/${blockId}`}>{blockDetail?.name}</Breadcrumb.Item>
            <Breadcrumb.Item>Thêm toà nhà</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <Button loading={loading} size="large" title="Lưu" type="primary" htmlType="submit">
          Lưu thông tin
        </Button>
      </Flex>

      <div className="building-form-container">
        <Row gutter={16}>
          <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24} span={8}>
            <Form.Item<ICRUDBuildingPayload>
              label="Tên toà nhà"
              name="name"
              rules={[{required: true, message: 'Trường này là bắt buộc!'}]}>
              <Input size="large" placeholder="Nhập tên toà nhà" />
            </Form.Item>
            <Form.Item<ICRUDBuildingPayload>
              label="Mã toà nhà"
              name="shortName"
              rules={[{required: true, message: 'Trường này là bắt buộc!'}]}>
              <Input size="large" placeholder="Nhập mã toà nhà, VD: U38, L27, ..." />
            </Form.Item>
            {!projectId || projectId === 'null' ? (
              <Form.Item<ICRUDBuildingPayload>
                label="Thuộc dự án"
                name="projectId"
                rules={[{required: true, message: 'Trường này là bắt buộc!'}]}>
                <Select
                  onDropdownVisibleChange={open => (open ? getListProjects() : null)}
                  size="large"
                  options={listProject}
                  fieldNames={{value: '_id', label: 'name'}}
                  placeholder="Chọn dự án"
                  value={form.getFieldValue('projectId')}
                  onChange={value => form.setFieldValue('projectId', value)}
                  loading={loadingProject}
                />
              </Form.Item>
            ) : (
              <></>
            )}
            {!blockId || blockId === 'null' ? (
              <Form.Item<ICRUDBuildingPayload> label="Thuộc phân khu" name="blockId">
                <Select
                  onDropdownVisibleChange={open => (open ? getListBlock() : null)}
                  size="large"
                  options={listBlock}
                  fieldNames={{value: '_id', label: 'name'}}
                  placeholder="Chọn phân khu"
                  value={form.getFieldValue('buildingId')}
                  onChange={value => form.setFieldValue('buildingId', value)}
                  loading={loadingBlock}
                />
              </Form.Item>
            ) : (
              <></>
            )}
            <Form.Item<ICRUDBuildingPayload> label="Chính sách bán hàng" name="salesPolicyLink">
              <Input size="large" placeholder="Nhập đường dẫn" />
            </Form.Item>
            <Form.Item<ICRUDBuildingPayload> label="Sa bàn 360" name="view360Link">
              <Input size="large" placeholder="Nhập đường dẫn sa bàn 360" />
            </Form.Item>
          </Col>
          <Col xxl={16} xl={16} lg={16} md={24} sm={24} xs={24} span={16}>
            <Form.Item<ICRUDBuildingPayload>
              label="Mô tả toà nhà"
              name="content"
              rules={[{required: true, message: 'Trường này là bắt buộc!'}]}>
              <ReactQuill
                style={{height: 350}}
                theme="snow"
                placeholder="Nhập mô tả dự án"
                modules={DEFAULT_QUILL_MODULES}
                formats={DEFAULT_QUILL_FORMAT}
                value={form.getFieldValue('content')}
                onChange={value => form.setFieldValue('content', value)}
              />
            </Form.Item>
          </Col>
        </Row>
        <LinkAndOverviewRowForm type="building" />
      </div>
    </Form>
  );
};

export default AddBuildingScreen;

import store from 'src/stores';
import {EUserRole} from './enum';
import {IStockInfo, IStockProperty} from 'src/services/stock/type';
import {ColumnsType} from 'antd/lib/table';
import {IBuildingInfo} from 'src/services/building/type';
import {Link} from 'react-router-dom';
//
export const translateUserRole = (): string => {
  const state = store.getState();
  const {auth} = state;
  const {user} = auth;
  if (user) {
    switch (user.role) {
      case EUserRole.GUEST:
        return 'Khách';
      case EUserRole.ADMIN:
        return 'Admin';
      case EUserRole.SALE_INTERNAL:
        return 'Sale nội bộ';
      case EUserRole.SALE_EXTERNAL:
        return 'Sale ngoài';
      case EUserRole.SUPER_ADMIN:
        return 'Giám đốc';
      default:
        return 'Khách';
    }
  }
  return '';
};
export const translateRoleText = (roleStr: EUserRole): string => {
  if (roleStr) {
    switch (roleStr) {
      case EUserRole.GUEST:
        return 'Khách';
      case EUserRole.ADMIN:
        return 'Admin';
      case EUserRole.SALE_INTERNAL:
        return 'Sale nội bộ';
      case EUserRole.SALE_EXTERNAL:
        return 'Sale ngoài';
      case EUserRole.SUPER_ADMIN:
        return 'Giám đốc';
      default:
        return 'Khách';
    }
  }
  return '';
};
// PERMISSONS
export const checkIsGuest = (): boolean => {
  const state = store.getState();
  const {auth} = state;
  const {user} = auth;
  if (user) {
    if (user.role === EUserRole.GUEST) {
      return true;
    }
  }
  return false;
};
export const checkIsSaleInternal = (): boolean => {
  const state = store.getState();
  const {auth} = state;
  const {user} = auth;
  if (user) {
    if (user.role === EUserRole.SALE_INTERNAL) {
      return true;
    }
  }
  return false;
};
export const checkIsSaleExternal = (): boolean => {
  const state = store.getState();
  const {auth} = state;
  const {user} = auth;
  if (user) {
    if (user.role === EUserRole.SALE_EXTERNAL) {
      return true;
    }
  }
  return false;
};
export const checkIsAdmin = (): boolean => {
  const state = store.getState();
  const {auth} = state;
  const {user} = auth;
  if (user) {
    if (user.role === EUserRole.ADMIN) {
      return true;
    }
  }
  return false;
};
export const checkIsSuperAdmin = (): boolean => {
  const state = store.getState();
  const {auth} = state;
  const {user} = auth;
  if (user) {
    if (user.role === EUserRole.SUPER_ADMIN) {
      return true;
    }
  }
  return false;
};

// FIND OBJECT
export const findExcelObjectByKey = (propertyKeys: IStockProperty[], key: string): IStockProperty | undefined => {
  if (propertyKeys.length > 0) {
    let findValue = propertyKeys.find(item => item.key === key);
    return findValue;
  }
  return undefined;
};
export const findExcelObjectContainKey = (propertyKeys: IStockProperty[], key: string): IStockProperty | undefined => {
  if (propertyKeys.length > 0) {
    let findValue = propertyKeys.find(item => item.key.includes(key));
    return findValue;
  }
  return undefined;
};
export const findExcelObjectByMultiKey = (
  propertyKeys: IStockProperty[],
  keys: string[],
): IStockProperty | undefined => {
  if (propertyKeys.length > 0) {
    let results: any[] = [];
    keys.forEach(key => {
      let findValue = propertyKeys.find(item => item.key.includes(key));
      results.push(findValue);
    });
    let returnValue = results.find(item => item);
    return returnValue;
  }
  return undefined;
};
export const getStockTableColumn = (detail: IBuildingInfo | null): ColumnsType<IStockInfo> => {
  if (detail && detail.columns) {
    const newColumnArr: ColumnsType<IStockInfo> = detail.columns.map((item, index) => {
      return {
        title: item.label,
        fixed: index === 0 ? 'left' : undefined,
        key: item.key,
        dataIndex: item.key,
        render: (_, dataItem) => {
          const dataObject = findExcelObjectByKey(dataItem.properties, item.key);
          if (dataObject) {
            const {textLink, value} = dataObject;
            if (textLink) {
              if (typeof value === 'string') {
                return (
                  <Link to={textLink} target="_blank">
                    {value}
                  </Link>
                );
              } else return <></>;
            } else {
              if (typeof value === 'string') {
                return <div>{value}</div>;
              } else return <></>;
            }
          } else {
            return <></>;
          }
        },
      };
    });
    return newColumnArr;
  } else return [];
};
// CONVERT
export const convertMoney = (money: string | undefined = '0', affix: string = '') => {
  if (money) {
    if (!isNaN(Number(money))) {
      let convertedValue = Intl.NumberFormat('vi-VN').format(Number(money));
      return `${convertedValue} ${affix}`;
    } else {
      return `${money} ${affix}`;
    }
  } else return '0đ';
};

import http, {IBaseHTTPPaging, IBaseHTTPResponse} from 'src/config/http';
import {PROFILE_ENDPOINT} from './endpoint';
import {
  IListUserParams,
  IReupdateHTMLResponse,
  IUpdateHTMLResponse,
  IUpdatePasswordPayload,
  IUpdateProfilePayload,
  IUpdateUserResponse,
  IUploadFileResponse,
  IUserEventResponse,
  IUserListResponse,
} from './type';
import {IHTMLResponse} from '../stock/type';

export default class ProfileService {
  public async listUser(params: IListUserParams) {
    const {data} = await http.get<IUserListResponse>(PROFILE_ENDPOINT.common, {params});
    return data;
  }
  public async updateProfile(id: number, payload: IUpdateProfilePayload) {
    const {data} = await http.post<IUpdateUserResponse>(`${PROFILE_ENDPOINT.common}/${id}`, payload);
    return data;
  }
  public async changePassword(id: number, payload: IUpdatePasswordPayload) {
    const {data} = await http.post<IBaseHTTPResponse>(`${PROFILE_ENDPOINT.common}/${id}/password`, payload);
    return data;
  }
  public async uploadFile(payload: FormData) {
    const {data} = await http.post<IUploadFileResponse>(PROFILE_ENDPOINT.uploadFile, payload, {
      headers: {'Content-Type': 'application/vnd.ms-excel'},
    });
    return data;
  }
  public async updateHTMLContent(id: string, payload: {html: string}) {
    const {data} = await http.post<IUpdateHTMLResponse>(
      `${PROFILE_ENDPOINT.updateHTMLContent}/${id}/renderHTML`,
      payload,
    );
    return data;
  }
  public async reUpdateHTMLContent(fileId: string) {
    const {data} = await http.get<IReupdateHTMLResponse>(`${PROFILE_ENDPOINT.updateHTMLContent}/${fileId}/reupdate`);
    return data;
  }
  public async deleteUser(userId: number) {
    const {data} = await http.delete<IHTMLResponse>(`${PROFILE_ENDPOINT.deleteUser}/${userId}`);
    return data;
  }
  public async getUserEvents(params: IBaseHTTPPaging) {
    const {data} = await http.get<IUserEventResponse>(PROFILE_ENDPOINT.getEvent, {params});
    return data;
  }
}

import {Rule} from 'antd/es/form';
import {isEmail, isMobilePhone} from 'validator';

export const validatePhoneNumber: Rule = ({}) => ({
  validator(_, value) {
    if (!value || isMobilePhone(value)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('SĐT không hợp lệ!'));
  },
});

export const validateConfirmPassword: Rule = ({getFieldValue}) => ({
  validator(_, value) {
    if (!value || getFieldValue('password') === value) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Mật khẩu không khớp!'));
  },
});
export const validateEmail: Rule = ({}) => ({
  validator(_, value) {
    if (!value || isEmail(value)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Email không hợp lệ!'));
  },
});

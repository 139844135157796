export const DEFAULT_NOTI_DURATION = 3.5;
export const DEFAULT_EXCEL_SAMPLE =
  'https://thienanjsc-my.sharepoint.com/:x:/g/personal/trunglbq_thienan_tech/EQhLkQo2gjVCms2WefxOe50BqY_ywz1aHEvE4f_V7kG72A?e=lOZsXj';
export const DEFAULT_STACKING_EXCEL_SAMPLE =
  'https://dongdoland-api-stag.thienan.tech/files/download/FILEH0AS3UNM.xlsx';
export const DEFAULT_QUILL_MODULES = {
  toolbar: [
    [{header: [1, 2, 3, 4, 5, false]}],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{list: 'ordered'}, {list: 'bullet'}, {indent: '-1'}, {indent: '+1'}],
    ['link', 'image'],
    ['clean'],
  ],
};
export const DEFAULT_QUILL_FORMAT = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
];

import http, {IBaseHTTPResponse} from 'src/config/http';
import {AUTHEN_ENDPOINT} from './endpoint';
import {
  IForgetPasswordPayload,
  IOauthPayload,
  ISendOTPPayload,
  ISendOTPResponse,
  ISignInPayload,
  ISignInResponse,
  ISignInWithPhonePayload,
  ISignUpPayload,
  IVerifyOTPPayload,
  IVerifyOTPResponse,
} from './type';

export default class AuthenService {
  public async signIn(payload: ISignInPayload) {
    const {data} = await http.post<ISignInResponse>(AUTHEN_ENDPOINT.signIn, payload);
    return data;
  }
  public async signInWithPhone(payload: ISignInWithPhonePayload) {
    const {data} = await http.post<ISignInResponse>(AUTHEN_ENDPOINT.signInWithPhone, payload);
    return data;
  }
  public async signUp(payload: ISignUpPayload) {
    const {data} = await http.post<IBaseHTTPResponse>(AUTHEN_ENDPOINT.signUp, payload);
    return data;
  }
  public async sendOTP(payload: ISendOTPPayload) {
    const {data} = await http.post<ISendOTPResponse>(AUTHEN_ENDPOINT.sendOTP, payload);
    return data;
  }
  public async verifyOTP(payload: IVerifyOTPPayload) {
    const {data} = await http.post<IVerifyOTPResponse>(AUTHEN_ENDPOINT.verifyOTP, payload);
    return data;
  }
  public async signInGoogle(payload: IOauthPayload) {
    const {data} = await http.post<ISignInResponse>(AUTHEN_ENDPOINT.oauth, payload);
    return data;
  }
  public async forgetPassword(payload: IForgetPasswordPayload) {
    const {data} = await http.post<IBaseHTTPResponse>(AUTHEN_ENDPOINT.forgetPassword, payload);
    return data;
  }
}

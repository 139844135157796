import {MinusCircleOutlined, PlusOutlined} from '@ant-design/icons';
import {Button, Col, Flex, Form, Input, Row, Typography} from 'antd';
import {FC, useMemo} from 'react';
import useWindowSize from 'src/hooks/useWindowSize';
//
interface IProps {
  type: 'block' | 'project' | 'building';
}
//
const {Title} = Typography;
//
const LinkAndOverviewRowForm: FC<IProps> = props => {
  const {isMobileMode} = useWindowSize();
  const {type} = props;
  const formTypeText: string = useMemo(() => {
    if (type === 'block') {
      return 'Phân khu';
    } else if (type === 'building') {
      return 'Toà nhà';
    } else if (type === 'project') {
      return 'Dự án';
    } else return '';
  }, [type]);
  return (
    <Row gutter={16} style={{marginTop: isMobileMode ? 65 : 50}}>
      <Form.List name="overviews">
        {(fields, {add, remove}) => (
          <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24} span={12}>
            <Flex align="center" gap={10} style={{marginBottom: 15}}>
              <Title level={3}>Tổng quan {formTypeText}</Title>
              <Button type="primary" onClick={() => add()} icon={<PlusOutlined />} size="small">
                Thêm trường
              </Button>
            </Flex>

            {fields.map(({name, key, ...restField}) => (
              <Flex gap={8} style={{marginBottom: 30}} align="flex-end">
                <Form.Item {...restField} layout="vertical" label="Tên trường" name={[name, 'title']}>
                  <Input placeholder="VD: Diện tích, Quy mô, Số toà, ..." />
                </Form.Item>
                <Form.Item {...restField} layout="vertical" label="Giá trị" name={[name, 'value']}>
                  <Input placeholder="VD: 420ha, 30 toà, ...." />
                </Form.Item>
                <Button type="text" onClick={() => remove(name)} icon={<MinusCircleOutlined />} size="small" />
              </Flex>
            ))}
          </Col>
        )}
      </Form.List>
      <Form.List name="links">
        {(fields, {add, remove}) => (
          <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24} span={12}>
            <Flex align="center" gap={10} style={{marginBottom: 15}}>
              <Title level={3}>Link đính kèm {formTypeText}</Title>
              <Button type="primary" onClick={() => add()} icon={<PlusOutlined />} size="small">
                Thêm trường
              </Button>
            </Flex>
            {fields.map(({name, key, ...restField}) => (
              <Flex gap={8} style={{marginBottom: 30}} align="flex-end">
                <Form.Item {...restField} layout="vertical" label="Tên link" name={[name, 'title']}>
                  <Input placeholder="VD: Layout, Mặt bằng, Tiện ích, ..." />
                </Form.Item>
                <Form.Item {...restField} layout="vertical" label="Giá trị" name={[name, 'value']}>
                  <Input placeholder="Nhập đường dẫn" />
                </Form.Item>
                <Button type="text" onClick={() => remove(name)} icon={<MinusCircleOutlined />} size="small"></Button>
              </Flex>
            ))}
          </Col>
        )}
      </Form.List>
    </Row>
  );
};
export default LinkAndOverviewRowForm;

import {useEffect, useState} from 'react';
interface IWindow {
  width: number;
  height: number;
}
const useWindowSize = () => {
  const screenOrientation = window.matchMedia('(orientation: landscape)');
  const [windowSize, setWindowSize] = useState<IWindow>({width: window.innerWidth, height: window.innerHeight});
  const [isMobileMode, setIsMobileMode] = useState<boolean>(window.innerWidth < 1200);
  const [isLandscape, setIsLandscape] = useState<boolean>(screenOrientation.matches);

  useEffect(() => {
    // Change size
    const windowSizeHandler = () => {
      setWindowSize({width: window.innerWidth, height: window.innerHeight});
      if (window.innerWidth < 1200) {
        setIsMobileMode(true);
      } else {
        setIsMobileMode(false);
      }
    };
    window.addEventListener('resize', windowSizeHandler);
    // Change orientation
    const windowOrientationHandler = (e: MediaQueryListEvent) => {
      const landscape = e.matches;
      setIsLandscape(landscape);
    };
    screenOrientation.addEventListener('change', e => windowOrientationHandler(e));
    return () => {
      window.removeEventListener('resize', windowSizeHandler);
      screenOrientation.removeEventListener('change', e => windowOrientationHandler(e));
    };
  }, []);
  return {windowSize, isMobileMode, commonTableHeight: windowSize.height * 0.45, isLandscape};
};
export default useWindowSize;

import {Button, Form, Input, Typography, notification} from 'antd';
import {AxiosError} from 'axios';
import {FC, useState} from 'react';
import {setUser} from 'src/screens/authentication/store';
import AccountService from 'src/services/account';
import {IUpdateAccountPayload} from 'src/services/account/type';
import {IUserInfo} from 'src/services/authentication/type';
import {useAppDispatch, useAppSelector} from 'src/stores/utils';
import {DEFAULT_NOTI_DURATION} from 'src/utils/common/constants';
//
const {Text, Title} = Typography;
//
const accountService = new AccountService();
//
const ProfileInformation: FC = () => {
  //
  const authState = useAppSelector(state => state.auth);
  const {user} = authState;
  const dispatch = useAppDispatch();
  //
  const [form] = Form.useForm<IUserInfo>();
  const [loading, setLoading] = useState<boolean>(false);
  //
  const onFinish = async (values: IUpdateAccountPayload) => {
    setLoading(true);
    try {
      let res = await accountService.updateAccount(values);
      if (res && res.code === 200) {
        dispatch(setUser(res.data));
        notification.success({
          message: 'Cập nhật thành công',
          description: res.message,
          duration: DEFAULT_NOTI_DURATION,
        });
      }
    } catch (err) {
      if (err instanceof AxiosError) {
        notification.error({
          message: 'Có lỗi xảy ra',
          description: err.response?.data.message,
          duration: DEFAULT_NOTI_DURATION,
        });
      }
    }
    setLoading(false);
  };
  return (
    <div className="form-container">
      <Form
        form={form}
        name="profile_info_form"
        labelCol={{span: 8}}
        wrapperCol={{span: 16}}
        labelAlign="left"
        colon={false}
        initialValues={{
          email: user?.email,
          displayName: user?.displayName,
          phoneNumber: user?.phoneNumber,
        }}
        autoComplete="off"
        onFinish={onFinish}>
        <Form.Item<IUserInfo>
          label="Email"
          name="email"
          extra={<Text disabled>E-mail này dùng để đăng nhập và không thể thay đổi</Text>}>
          <Input placeholder="Nhập email" disabled />
        </Form.Item>

        <Title level={4} style={{marginBottom: 15}}>
          Thông tin cá nhân
        </Title>
        <Form.Item<IUserInfo> label="Họ và tên" name="displayName">
          <Input placeholder="Nhập họ và tên" />
        </Form.Item>
        <Form.Item<IUserInfo> label="Điện thoại" name="phoneNumber">
          <Input placeholder="Nhập số điện thoại" />
        </Form.Item>

        {/* <Form.Item<IUserInfo> label="Giới tính" name="phoneNumber">
          <Input placeholder="Nhập số điện thoại" />
        </Form.Item> */}
        <Form.Item<IUserInfo> label=" ">
          <Button loading={loading} type="primary" htmlType="submit" block>
            Lưu thay đổi
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
export default ProfileInformation;

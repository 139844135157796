import { Breadcrumb, Button, Flex, Form, Spin, Tabs, Typography, notification } from 'antd';
import { FC, useEffect, useState } from 'react';
import { IBuildingInfo, ICRUDBuildingPayload } from 'src/services/building/type';
//
import 'react-quill/dist/quill.snow.css';
import { useParams } from 'react-router';
import { ROUTE_CONFIG } from 'src/routes/config';
import ScreenParams from 'src/routes/screenParams';
import UploadTrackingPlanForm from 'src/screens/building/updateBuilding/components/trackingPlanForm';
import BuildingService from 'src/services/building';
import { toggleReloadProject } from 'src/stores/common/layout.slice';
import { useAppDispatch } from 'src/stores/utils';
import { DEFAULT_NOTI_DURATION } from 'src/utils/common/constants';
import { showNotiErr } from 'src/utils/notification';
import '../styles.scss';
import BuildingTableForm from './components/buildingTableForm';
import InformationForm from './components/informationForm';
import { EUpdateBuildingTab } from './type';
//
const {Title} = Typography;
const buildingService = new BuildingService();

//
const UpdateBuildingScreen: FC = () => {
  const {id} = useParams<ScreenParams['UPDATE_BUILDING']>();
  const dispatch = useAppDispatch();
  //
  const [form] = Form.useForm<ICRUDBuildingPayload>();
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  //
  const [loading, setLoading] = useState<boolean>(false);
  const [buildingDetail, setBuildingDetail] = useState<IBuildingInfo | null>(null);
  const [activeTab, setActiveTab] = useState<EUpdateBuildingTab | string>(EUpdateBuildingTab.info);
  const [reload, setReload] = useState<boolean>(false);
  //
  useEffect(() => {
    getBuildingInfo();
  }, [id, reload]);
  //
  const getBuildingInfo = async () => {
    if (id) {
      setLoading(true);
      await buildingService
        .getBuildingDetail(id)
        .then(res => {
          const {data} = res;
          setBuildingDetail(data);
          form.setFieldsValue({
            name: data.name,
            shortName: data.shortName,
            content: data.content || '',
            priceSheetLink: data.priceSheetLink || '',
            projectId: data.projectId,
            salesPolicyLink: data.salesPolicyLink || '',
            view360Link: data.view360Link || '',
            blockId: data.blockId || '',
            links: data.links,
            overviews: data.overviews,
          });
        })
        .catch(err => showNotiErr(err))
        .finally(() => setLoading(false));
    }
  };
  const handleReload = () => {
    setReload(prev => !prev);
  };
  //
  const onFinish = async (values: ICRUDBuildingPayload) => {
    setLoadingSubmit(true);
    buildingService
      .updateBuilding(buildingDetail?._id || '', {...values, projectId: buildingDetail?.projectId})
      .then(res => {
        notification.success({
          message: res.message,
          duration: DEFAULT_NOTI_DURATION,
        });
        dispatch(toggleReloadProject());
      })
      .catch(err => showNotiErr(err))
      .finally(() => {
        setLoadingSubmit(false);
        handleReload();
      });
  };
  return (
    <Form form={form} labelCol={{span: 24}} wrapperCol={{span: 24}} autoComplete="off" onFinish={onFinish}>
      <Flex justify="space-between" align="flex-end">
        <div>
          <Title level={3}>Chỉnh sửa toà nhà</Title>
          <Breadcrumb>
            <Breadcrumb.Item href="/">Trang chủ</Breadcrumb.Item>
            {buildingDetail && buildingDetail.project && (
              <Breadcrumb.Item href={`${ROUTE_CONFIG.PROJECT_INFO}/${buildingDetail.projectId}`}>
                {buildingDetail.project.name}
              </Breadcrumb.Item>
            )}
            {buildingDetail && buildingDetail.block && (
              <Breadcrumb.Item href={`${ROUTE_CONFIG.BLOCK_INFO}/${buildingDetail.blockId}`}>
                {buildingDetail.block.name}
              </Breadcrumb.Item>
            )}
            <Breadcrumb.Item>{buildingDetail?.name}</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <Button loading={loadingSubmit} size="large" title="Lưu" type="primary" htmlType="submit">
          Lưu thông tin
        </Button>
      </Flex>

      <div className="building-form-container">
        <Tabs
          defaultValue={activeTab}
          onChange={setActiveTab}
          items={[
            {
              key: EUpdateBuildingTab.info,
              label: 'Thông tin toà nhà',
              children: <InformationForm form={form} detail={buildingDetail} />,
            },
            {
              key: EUpdateBuildingTab.table,
              label: 'Bảng hàng',
              children: <BuildingTableForm detail={buildingDetail} onReload={handleReload} form={form} />,
            },
            {
              key: EUpdateBuildingTab.plan,
              label: 'Stacking Plan',
              children: <UploadTrackingPlanForm detail={buildingDetail} />,
            },
          ]}
        />
      </div>
      {loading && <Spin size="large" fullscreen />}
    </Form>
  );
};

export default UpdateBuildingScreen;

import Axios from 'axios';
import {API_DOMAIN} from './environtment';
import store from 'src/stores';
import {ISignInResponse} from 'src/services/authentication/type';
import {AUTHEN_ENDPOINT} from 'src/services/authentication/endpoint';
import {setIsLogin, setTokens, setUser} from 'src/screens/authentication/store';
import {Button, notification} from 'antd';

const REQ_TIMEOUT = 30 * 1000;
export interface IBaseHTTPResponse {
  code?: number;
  message: string;
  status: string;
}
export interface IBaseHTTPPaging {
  results: number;
  page: number;
  sortOrder?: 'descend' | 'ascend';
  sortField?: string;
}
const http = Axios.create({
  baseURL: API_DOMAIN,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
  timeout: REQ_TIMEOUT,
  // withCredentials: true,
});

http.interceptors.request.use(request => {
  const appState = store.getState();
  const {auth} = appState;
  const {tokens} = auth;
  if (request.headers) {
    request.headers.authorization = `Bearer ${tokens.accessToken}`;
  }

  return request;
});

/**
 * intercepts any response and checks the response from our api for a 401 status in the response. ie. the token has now expired and is no longer valid, or no valid token was sent.
 * If such a status exists then we log out the user and clear the profile from redux state.
 */
http.interceptors.response.use(
  res => res,
  err => {
    if (err.response.status === 401) {
      const appState = store.getState();
      const {auth} = appState;
      const {tokens} = auth;
      const {refreshToken} = tokens;
      if (!refreshToken) {
        return;
      } else {
        http
          .post<ISignInResponse>(AUTHEN_ENDPOINT.refreshToken, {refreshToken})
          .then(res => {
            const {data} = res;
            const {data: responseData} = data;
            const {tokens} = responseData;
            store.dispatch(setTokens(tokens));
            if (err.config !== undefined && err.config.headers !== undefined) {
              err.config.headers['Authorization'] = 'Bearer ' + tokens.accessToken;

              return http(err.config);
            } else {
              return;
            }
          })
          .catch(err => {
            const {auth} = store.getState();
            const {tokens} = auth;
            const {accessToken} = tokens;
            if (accessToken) {
              notification.warning({
                message: 'Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại',
                btn: (
                  <Button
                    onClick={() => {
                      store.dispatch(
                        setTokens({
                          accessToken: '',
                          refreshToken: '',
                        }),
                      );
                      store.dispatch(
                        setTokens({
                          accessToken: '',
                          refreshToken: '',
                        }),
                      );
                      store.dispatch(setUser(null));
                      store.dispatch(setIsLogin(false));
                      window.location.href = '/signin';
                    }}>
                    Đăng nhập lại
                  </Button>
                ),
              });
            } else {
              // Vui lòng đăng nhập để tiếp tục sử dụng ứng dụng
            }
          });
      }
    } else {
      return Promise.reject(err);
    }
  },
);

export default http;

import {FC} from 'react';
import {IDashboardProjectInfo} from '../../type';
import {Card, Divider, Flex, Image, List, Tag, Typography} from 'antd';
import {ROUTE_CONFIG} from 'src/routes/config';
//
interface IProjectCardProps {
  project: IDashboardProjectInfo;
}
//
const {Text, Title, Link} = Typography;
//
const ProjectCard: FC<IProjectCardProps> = props => {
  const {project} = props;
  const overviewList = project && project.overviews ? project.overviews.slice(0, 3) : [];
  return (
    <Card
      cover={<Image alt="projectImg" src="https://user.futurehomes.vn/images/BDS/vinhomes-ocean-park-1.jpg" />}
      style={{marginBottom: 16}}>
      <Card.Meta
        title={<Link href={`${ROUTE_CONFIG.PROJECT_INFO}/${project._id}`}>{project.name}</Link>}
        description={
          <Flex align="center" gap={5}>
            {project.links?.map((link, index) => (
              <Tag color="blue" key={index}>
                {link.title}
              </Tag>
            ))}
          </Flex>
        }
      />
      <Divider />
      <Flex align="center" justify="space-between">
        {overviewList.map((item, index) => (
          <Flex key={index} vertical align="center">
            <Text>{item.value}</Text>
            <Title level={5}>{item.title}</Title>
          </Flex>
        ))}
      </Flex>
      <Divider />
      <List
        size="small"
        dataSource={project.blockList}
        renderItem={item => (
          <List.Item>
            <List.Item.Meta
              title={<Link href={`${ROUTE_CONFIG.BLOCK_INFO}/${item._id}`}>{item.name}</Link>}
              description={
                <Flex align="center" gap={5}>
                  {item.links?.map((item, index) => (
                    <Tag style={{cursor: 'pointer'}} onClick={() => window.open(item.value, '_blank')} key={index}>
                      {item.title}
                    </Tag>
                  ))}
                </Flex>
              }
            />
          </List.Item>
        )}
      />
    </Card>
  );
};
export default ProjectCard;

import {Button, Form, Input, notification, Typography} from 'antd';
import {FC, Fragment, useState} from 'react';
import AuthenService from 'src/services/authentication';
import {IForgetPasswordPayload} from 'src/services/authentication/type';
import {showNotiErr} from 'src/utils/notification';
import {validateEmail} from '../../Authen.utils';
//
const authenService = new AuthenService();
const {Title, Paragraph} = Typography;
//
const EnterMail: FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  //
  const onFinish = async (values: IForgetPasswordPayload) => {
    setLoading(true);
    authenService
      .forgetPassword(values)
      .then(res => {
        const {message} = res;
        notification.success({
          message: 'Gửi yêu cầu thành công',
          description: message,
        });
        // navigate(ROUTE_CONFIG.HOME);
      })
      .catch(err => {
        showNotiErr(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <Fragment>
      <Title level={4}>Quên mật khẩu</Title>
      <Paragraph>Vui lòng nhập địa chỉ e-mail mà bạn muốn nhận mã xác nhận</Paragraph>
      <Form
        name="forget_pass_form"
        labelCol={{span: 24}}
        wrapperCol={{span: 24}}
        initialValues={{
          email: '',
          password: '',
        }}
        autoComplete="off"
        onFinish={onFinish}>
        <Form.Item<IForgetPasswordPayload>
          label="Email"
          name="email"
          rules={[{required: true, message: 'Email là bắt buộc!'}, validateEmail]}>
          <Input placeholder="Nhập email" />
        </Form.Item>
        <Button loading={loading} type="primary" htmlType="submit" block>
          Xác nhận
        </Button>
      </Form>
    </Fragment>
  );
};
export default EnterMail;

import {ThemeConfig} from 'antd';

export const COMPONENT_TOKEN: ThemeConfig['components'] = {
  Layout: {
    bodyBg: '#f5f5f9',
    headerBg: '#ffffff',
    headerHeight: 60,
    headerPadding: 15,
  },
  Divider: {
    orientationMargin: 0,
  },
  Form: {
    verticalLabelMargin: 0,
    labelHeight: 14,
    itemMarginBottom: 10,
  },
  Typography: {
    titleMarginBottom: 5,
    titleMarginTop: 5,
  },
  Table: {
    cellFontSize: 12,
    cellFontSizeMD: 12,
    cellFontSizeSM: 12,
  },
  Menu: {
    itemSelectedColor: '#f67b13',
    itemHoverBg: '#f9e4c9',
    itemSelectedBg: '#f9e4c9s',
    itemActiveBg: '#f9e4c9'
  }
};

export const GLOBAL_TOKEN: ThemeConfig['token'] = {
  margin: 15,
  marginSM: 10,
  marginXXS: 5,
  marginXL: 20,
};

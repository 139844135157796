import {Button, Form, Input, Modal, ModalProps, Space, Typography} from 'antd';
import {FC, Fragment, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {ROUTE_CONFIG} from 'src/routes/config';
import {validatePhoneNumber} from 'src/screens/authentication/Authen.utils';
import AuthenService from 'src/services/authentication';
import {showNotiErr} from 'src/utils/notification';
//
interface IFormFields {
  phoneNumber: string;
}
//
const {Text} = Typography;
const authenService = new AuthenService();
//
const AddPhoneModal: FC<ModalProps> = props => {
  const navigate = useNavigate();
  const [form] = Form.useForm<IFormFields>();
  //
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  //
  const onFinish = async (values: IFormFields) => {
    const {phoneNumber} = values;
    setLoadingSubmit(true);
    await authenService
      .sendOTP({phoneNumber})
      .then(res => {
        const {data} = res;
        const {requestToken, retryAfterSeconds} = data;
        // Pass data sang OTP
        Modal.destroyAll();
        navigate(ROUTE_CONFIG.OTP, {
          state: {
            phoneNumber: phoneNumber,
            requestToken,
            retryAfterSeconds,
          },
        });
      })
      .catch(err => showNotiErr(err));

    setLoadingSubmit(false);
  };
  return (
    <Modal
      {...props}
      title="Thêm số điện thoại"
      footer={(_, {CancelBtn}) => (
        <Fragment>
          <CancelBtn />
          <Button loading={loadingSubmit} onClick={form.submit} type="primary">
            Tiếp tục
          </Button>
        </Fragment>
      )}>
      <Space direction="vertical" size="middle">
        <Text>Tài khoản này chưa có số điện thoại, vui lòng cung cấp thêm số điện thoại cho tài khoản</Text>
        <Form form={form} name="add_phone_form" labelCol={{span: 24}} wrapperCol={{span: 24}} onFinish={onFinish}>
          <Form.Item<IFormFields>
            label=""
            name="phoneNumber"
            rules={[{required: true, message: 'Trường này là bắt buộc!'}, validatePhoneNumber]}>
            <Input size="large" placeholder="Nhập số điện thoại" />
          </Form.Item>
        </Form>
      </Space>
    </Modal>
  );
};
export default AddPhoneModal;

import {Breadcrumb, Table, TableProps, Typography} from 'antd';
import moment from 'moment';
import {FC, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {IBaseHTTPPaging} from 'src/config/http';
import useWindowSize from 'src/hooks/useWindowSize';
import {ROUTE_CONFIG} from 'src/routes/config';
import ProfileService from 'src/services/profile';
import {IUserEvent} from 'src/services/profile/type';
import {translateRoleText} from 'src/utils/common/function';
import {showNotiErr} from 'src/utils/notification';
//
const {Title} = Typography;
const profileService = new ProfileService();
//
const UserEventManage: FC = () => {
  const {commonTableHeight} = useWindowSize();
  //
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<IUserEvent[]>([]);
  const [tableParams, setTableParams] = useState<IBaseHTTPPaging>({
    page: 1,
    results: 10,
    sortField: 'createdAt',
    sortOrder: 'descend',
  });
  const [total, setTotal] = useState<number>(0);
  const [reload, setReload] = useState<boolean>(false);
  //
  const onReload = () => setReload(prev => !prev);

  const columns: TableProps<IUserEvent>['columns'] = [
    {
      title: 'Tên người dùng',
      dataIndex: 'userId',
      key: 'userId',
      render: (_, record) => (
        <span>
          {record.user.displayName} ({translateRoleText(record.user.role)})
        </span>
      ),
      fixed: 'left',
    },
    {
      title: 'SĐT',
      dataIndex: 'user.phoneNumber',
      key: 'user.phoneNumber',
      render: (_, record) => <span>{record.user.phoneNumber}</span>,
    },
    {
      title: 'Sự kiện',
      dataIndex: 'eventValue',
      key: 'eventValue',
    },
    {
      title: 'Căn hộ quan tâm',
      dataIndex: 'stock',
      key: 'stock',
      render: (_, record) => (
        <Link to={`${ROUTE_CONFIG.BUILDING_INFO}/${record.stock?.buildingId}`}>
          {record.stock && record.stock.code}
        </Link>
      ),
    },
    {
      title: 'Số lần bấm',
      dataIndex: 'viewCount',
      key: 'viewCount',
    },
    {
      title: 'Quan tâm gần nhất',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (_, record) => <span>{moment(record.updatedAt).format('DD/MM/YYYY HH:mm')}</span>,
    },
  ];
  //
  useEffect(() => {
    getListUser();
  }, [tableParams.page, tableParams.results, reload]);
  //

  const getListUser = async () => {
    setLoading(true);

    profileService
      .getUserEvents(tableParams)
      .then(res => {
        const {data} = res;
        const {list, total} = data;
        setData(list);
        setTotal(total);
      })
      .catch(err => {
        showNotiErr(err);
      })
      .finally(() => setLoading(false));
  };
  const handleTableChange: TableProps['onChange'] = pagination => {
    if (pagination && pagination.current && pagination.pageSize) {
      setTableParams({
        page: pagination.current,
        results: pagination.pageSize,
      });

      // `dataSource` is useless since `pageSize` changed
      if (pagination.pageSize !== tableParams?.results) {
        setData([]);
      }
    }
  };
  //
  return (
    <div>
      <Title level={3}>Căn hộ được quan tâm</Title>
      <Breadcrumb>
        <Breadcrumb.Item href="/">Trang chủ</Breadcrumb.Item>
        <Breadcrumb.Item>Căn hộ được quan tâm</Breadcrumb.Item>
      </Breadcrumb>
      <div style={{marginTop: 15}}>
        <Table
          columns={columns}
          dataSource={data}
          loading={loading}
          rowKey={record => record._id}
          pagination={{total, current: tableParams.page, pageSize: tableParams.results}}
          onChange={handleTableChange}
          scroll={{y: commonTableHeight}}
        />
      </div>
    </div>
  );
};
export default UserEventManage;

import {Col, Empty, Flex, Row, Select, Spin, Table, Typography} from 'antd';
import {FC, useEffect, useState} from 'react';
import ExcelTable from 'src/components/common/excelTable';
import MultiViewsSegmented from 'src/components/common/multiViewsSegment';
import useWindowSize from 'src/hooks/useWindowSize';
import BlockService from 'src/services/block';
import {IBlockInfo} from 'src/services/block/type';
import BuildingService from 'src/services/building';
import {IBuildingInfo} from 'src/services/building/type';
import ProjectService from 'src/services/project';
import StockService from 'src/services/stock';
import {IStockInfo} from 'src/services/stock/type';
import {EMultiViews, EUserRole} from 'src/utils/common/enum';
import {checkIsAdmin, checkIsSuperAdmin, getStockTableColumn} from 'src/utils/common/function';
import {showNotiErr} from 'src/utils/notification';
import BuildingListCard from './components/buildingListCard';
import ProjectCard from './components/projectCard';
import './styles.scss';
import {IDashboardProjectInfo} from './type';
import BuildingToolbar from 'src/components/common/buildingToolbar';
import {useAppSelector} from 'src/stores/utils';
import ProfileService from 'src/services/profile';
import {getTableHorizontalSpace} from 'src/styles/utils';
//
const {Text, Title} = Typography;
//

const buildingService = new BuildingService();
const stockService = new StockService();
const projectService = new ProjectService();
const blockService = new BlockService();
const profileService = new ProfileService();
//
const DashboardScreen: FC = () => {
  //
  const {commonTableHeight} = useWindowSize();
  const {user} = useAppSelector(state => state.auth);
  //
  const [currentViewType, setCurrentViewType] = useState<EMultiViews | string>(EMultiViews.full);
  const [loadingProject, setLoadingProject] = useState<boolean>(false);
  const [loadingBuilding, setLoadingBuilding] = useState<boolean>(false);
  const [loadingTable, setLoadingTable] = useState<boolean>(false);
  const [loadingPlan, setLoadingPlan] = useState<boolean>(false);
  //
  const [buildingList, setBuildingList] = useState<IBuildingInfo[]>([]);
  const [currentBuilding, setCurrentBuilding] = useState<IBuildingInfo | null>(null);
  const [projectList, setProjectList] = useState<IDashboardProjectInfo[]>([]);
  const [stockData, setStockData] = useState<IStockInfo[]>([]);
  const [blockList, setBlockList] = useState<IBlockInfo[]>([]);
  const [excelTable, setExcelTable] = useState<string>('');
  const [reload, setReload] = useState<boolean>(false);
  //
  const tableColumn = getStockTableColumn(currentBuilding);
  //
  useEffect(() => {
    getListBuilding();
    getListBlock();
  }, []);
  useEffect(() => {
    getListProjects();
  }, [buildingList, blockList]);
  useEffect(() => {
    getTableData();
    getExcelTableContent();
  }, [currentBuilding, reload]);
  useEffect(() => {
    getExcelTableContent();
  }, [currentBuilding]);
  //
  const getListBuilding = async () => {
    setLoadingBuilding(true);
    buildingService
      .listBuilding({
        page: 1,
        results: 50,
      })
      .then(res => {
        const {data} = res;
        const {list} = data;
        setBuildingList(list);
        setCurrentBuilding(list[0]);
      })
      .catch(err => showNotiErr(err))
      .finally(() => setLoadingBuilding(false));
  };
  const getListBlock = async () => {
    blockService
      .listBlock()
      .then(res => {
        const {data} = res;
        const {list} = data;
        setBlockList(list);
      })
      .catch(err => showNotiErr(err));
  };
  const getListProjects = async () => {
    if (buildingList.length > 0 && blockList.length > 0) {
      setLoadingProject(true);
      projectService
        .listProject({
          page: 1,
          results: 50,
        })
        .then(res => {
          const {data} = res;
          const {list} = data;
          const savedProjectList: IDashboardProjectInfo[] = [];
          list.forEach(item => savedProjectList.push({...item, buildingList: [], blockList: []}));
          savedProjectList.forEach(project => {
            // Add building
            const buidlingInProject = buildingList.filter(item => item.projectId === project._id);
            project.buildingList = buidlingInProject;
            // Add block
            const blockInProject = blockList.filter(item => item.projectId === project._id);
            project.blockList = blockInProject;
          });
          setProjectList(savedProjectList);
        })
        .catch(err => showNotiErr(err))
        .finally(() => setLoadingProject(false));
    } else return;
  };
  const getTableData = async () => {
    if (currentBuilding) {
      setLoadingTable(true);
      // Lấy danh sách toà nhà
      const {worksheetStockNames} = currentBuilding;
      stockService
        .listStock({
          buildingId: currentBuilding?._id,
          projectId: currentBuilding.projectId,
          page: 1,
          results: 200,
          sheetName: worksheetStockNames && worksheetStockNames.length > 0 ? worksheetStockNames[0] : undefined,
        })
        .then(res => {
          const {data} = res;
          const {list} = data;
          const isNotAdmin = !checkIsAdmin() && !checkIsSuperAdmin();
          if (isNotAdmin) {
            if (user) {
              const currentRole = user.role;
              if (currentRole) {
                const canReadStockList = list.filter(item =>
                  item.canRead && item.canRead.length > 0 ? item.canRead.includes(currentRole) : true,
                );

                setStockData(canReadStockList);
              }
            } else {
              const canReadStockList = list.filter(item =>
                item.canRead && item.canRead.length > 0 ? item.canRead.includes(EUserRole.GUEST) : true,
              );
              setStockData(canReadStockList);
            }
          } else {
            setStockData(list);
          }
        })
        .catch(err => showNotiErr(err))
        .finally(() => setLoadingTable(false));
    }
  };
  const getExcelTableContent = async () => {
    if (currentBuilding && currentBuilding.fileId) {
      setLoadingPlan(true);
      // Get File excel
      stockService
        .renderHTMLJSON(currentBuilding.fileId)
        .then(htmlRes => {
          const {data} = htmlRes;
          const {render} = data;
          if (render) {
            setExcelTable(render.html);
          }
        })
        .catch(err => showNotiErr(err))
        .finally(() => {
          setLoadingPlan(false);
        });
    } else {
      return;
    }
  };
  const reUpdateExcelTable = async () => {
    if (currentBuilding && currentBuilding.fileId) {
      // Get File excel
      setLoadingPlan(true);
      profileService
        .reUpdateHTMLContent(currentBuilding.fileId)
        .then(htmlRes => {
          const {data} = htmlRes;
          const {html} = data;

          setExcelTable(html);
        })
        .catch(err => showNotiErr(err))
        .finally(() => {
          setLoadingPlan(false);
        });
    } else {
      return;
    }
  };
  //
  const handleChangeViewType = (type: EMultiViews | string) => {
    if (type !== currentViewType) {
      setCurrentViewType(type);
    }
  };
  const handleReload = () => {
    setReload(prev => !prev);
  };
  //
  return (
    <div>
      <Flex vertical align="center" justify="center" style={{padding: 40}}>
        <Title style={{textAlign: 'center'}} level={2}>
          DongDoLand - công cụ hữu ích dành cho sale BĐS Đông Đô Land
        </Title>
        <Text style={{fontSize: 18, textAlign: 'center'}}>
          Khám phá DongDoLand để trải nghiệm một cách tiếp cận hoàn toàn mới trong việc tìm kiếm thông tin dự án, đảm
          bảo rằng bạn sẽ có trong tay mọi thông tin hữu ích.
        </Text>
      </Flex>
      <div className="section-container">
        <Flex justify="space-between" align="flex-end" style={{marginBottom: 10}}>
          <Title level={4}>Bảng hàng dự án</Title>
          <Select
            style={{width: 150}}
            size="middle"
            options={buildingList}
            fieldNames={{value: '_id', label: 'name'}}
            placeholder="Chọn toà nhà"
            value={currentBuilding?._id}
            onChange={(value, item) => {
              setCurrentBuilding(item as IBuildingInfo);
            }}
            loading={loadingBuilding}
          />
        </Flex>
        <MultiViewsSegmented
          currentViewType={currentViewType}
          onChangeView={handleChangeViewType}
          onReload={currentViewType === EMultiViews.simple ? handleReload : reUpdateExcelTable}
          firstView={
            <Table
              loading={loadingTable}
              size="small"
              dataSource={stockData}
              bordered
              scroll={{x: getTableHorizontalSpace(), y: commonTableHeight}}
              columns={tableColumn}
            />
          }
          secondView={
            excelTable ? (
              <ExcelTable
                html={excelTable}
                projectId={currentBuilding?.projectId || ''}
                buildingId={currentBuilding?._id || ''}
                loading={loadingPlan}
              />
            ) : (
              <Empty />
            )
          }
        />
        <BuildingToolbar buildingDetail={currentBuilding} />
      </div>
      <Row gutter={24}>
        {projectList.map((project, index) => (
          <Col key={project._id} xxl={8} xl={8} lg={8} md={12} sm={24} xs={24} span={8}>
            <ProjectCard project={project} />
            <BuildingListCard project={project} />
          </Col>
        ))}
        {loadingProject && <Spin />}
      </Row>
    </div>
  );
};
export default DashboardScreen;

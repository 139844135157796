import {Select} from 'antd';
import {DefaultOptionType} from 'antd/es/select';
import {FC, useState} from 'react';
import StockService from 'src/services/stock';
import {IStockInfo} from 'src/services/stock/type';
import {EUserRole} from 'src/utils/common/enum';
import {showNotiErr} from 'src/utils/notification';
//
interface IRolePickerProps {
  stock: IStockInfo;
  onUpdateStock: (id: string) => void;
}
const dataOptions: DefaultOptionType[] = [
  {
    value: EUserRole.SALE_EXTERNAL,
    label: 'Sale đối tác',
  },
  {
    value: EUserRole.SALE_INTERNAL,
    label: 'Sale nội bộ',
  },
  {
    value: EUserRole.GUEST,
    label: 'Khách',
  },
];
const stockService = new StockService();
//
const CanSeePicker: FC<IRolePickerProps> = props => {
  const {stock, onUpdateStock} = props;
  const {canRead} = stock;
  const [loading, setLoading] = useState<boolean>(false);
  const [permissions, setPermissions] = useState<string[] | undefined>(canRead);
  const handleChange = async (value: string[]) => {
    setPermissions(value);
    setLoading(true);
    stockService
      .updateStock(stock._id, {
        canRead: value,
      })
      .then(res => {
        const {data} = res;
        onUpdateStock(data._id);
      })
      .catch(err => showNotiErr(err))
      .finally(() => setLoading(false));
  };
  return (
    <Select
      mode="multiple"
      allowClear
      loading={loading}
      disabled={loading}
      options={dataOptions}
      defaultValue={permissions || ([] as any)}
      placeholder="Tất cả các quyền"
      style={{width: 220}}
      onChange={handleChange}
    />
  );
};
export default CanSeePicker;

import {PayloadAction, createSlice} from '@reduxjs/toolkit';

interface State {
  reloadListBuilding: boolean;
  reloadListProject: boolean;
  showMobileMenu: boolean;
  openKeys: string[];
  selectedKey: string;
}

const initialState: State = {
  reloadListBuilding: false,
  reloadListProject: false,
  showMobileMenu: false,
  openKeys: ['/'],
  selectedKey: '/',
};

const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    toggleReloadBuilding: state => ({
      ...state,
      reloadListBuilding: !state.reloadListBuilding,
    }),
    toggleReloadProject: state => ({
      ...state,
      reloadListProject: !state.reloadListProject,
    }),
    openMobileMenu: state => ({
      ...state,
      showMobileMenu: true,
    }),
    closeMobileMenu: state => ({
      ...state,
      showMobileMenu: false,
    }),
    changeOpenKeys: (state, {payload}: PayloadAction<string[]>) => ({
      ...state,
      openKeys: payload,
    }),
    changeSelectedKeys: (state, {payload}: PayloadAction<string>) => ({
      ...state,
      selectedKey: payload,
    }),
  },
});

export const {reducer, actions} = layoutSlice;
export const {
  toggleReloadBuilding,
  toggleReloadProject,
  openMobileMenu,
  closeMobileMenu,
  changeOpenKeys,
  changeSelectedKeys,
} = actions;

import {Breadcrumb, Button, Col, Flex, Form, Input, Row, Select, Typography, notification} from 'antd';
import {FC, useEffect, useState} from 'react';
import ReactQuill from 'react-quill';
//
import 'react-quill/dist/quill.snow.css';
import {useNavigate, useParams} from 'react-router-dom';
import LinkAndOverviewRowForm from 'src/components/common/addLinksAndOverview';
import {ROUTE_CONFIG} from 'src/routes/config';
import ScreenParams from 'src/routes/screenParams';
import BlockService from 'src/services/block';
import {ICRUDBlockPayload} from 'src/services/block/type';
import ProjectService from 'src/services/project';
import {IProjectInfo} from 'src/services/project/type';
import {toggleReloadProject} from 'src/stores/common/layout.slice';
import {useAppDispatch} from 'src/stores/utils';
import {DEFAULT_QUILL_FORMAT, DEFAULT_QUILL_MODULES} from 'src/utils/common/constants';
import {showNotiErr} from 'src/utils/notification';
//
const {Title} = Typography;
const projectService = new ProjectService();
const blockService = new BlockService();
//
const AddBlockScreen: FC = () => {
  const {projectId} = useParams<ScreenParams['ADD_BLOCK']>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  //
  const [form] = Form.useForm<ICRUDBlockPayload>();
  const [loading, setLoading] = useState<boolean>(false);
  const [projectDetail, setProjectDetail] = useState<IProjectInfo | null>(null);
  const [loadingProject, setLoadingProject] = useState<boolean>(false);
  const [listProject, setListProject] = useState<IProjectInfo[]>([]);
  //
  useEffect(() => {
    getProjectInfo();
  }, [projectId]);
  //
  const getProjectInfo = async () => {
    if (projectId && projectId !== 'null') {
      projectService
        .getProjectDetail(projectId)
        .then(res => {
          const {data} = res;
          setProjectDetail(data);
        })
        .catch(err => showNotiErr(err));
    } else {
      setProjectDetail(null);
    }
  };
  //
  const getListProjects = async () => {
    setLoadingProject(true);
    projectService
      .listProject({
        page: 1,
        results: 50,
        sortField: 'orderBy',
        sortOrder: 'ascend',
      })
      .then(res => {
        const {data} = res;
        const {list} = data;
        setListProject(list);
      })
      .catch(err => showNotiErr(err))
      .finally(() => setLoadingProject(false));
  };
  //
  const onFinish = async (values: ICRUDBlockPayload) => {
    setLoading(true);
    blockService
      .createBlock({
        projectId: projectId && projectId !== 'null' ? projectId : '',
        name: values.name,
        description: values.description,
        links: values.links,
        overviews: values.overviews,
      })
      .then(res => {
        const {message, data} = res;
        notification.success({
          message,
        });
        dispatch(toggleReloadProject());
        navigate(`${ROUTE_CONFIG.BLOCK_INFO}/${data._id}`);
      })
      .catch(err => showNotiErr(err))
      .finally(() => setLoading(false));
  };

  return (
    <Form form={form} labelCol={{span: 24}} wrapperCol={{span: 24}} autoComplete="off" onFinish={onFinish}>
      <Flex justify="space-between" align="center">
        <div>
          <Title level={3}>Thêm phân khu</Title>
          <Breadcrumb>
            <Breadcrumb.Item href="/">Trang chủ</Breadcrumb.Item>
            <Breadcrumb.Item href={`${ROUTE_CONFIG.PROJECT_INFO}/${projectId}`}>{projectDetail?.name}</Breadcrumb.Item>
            <Breadcrumb.Item>Thêm phân khu</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <Button loading={loading} size="large" title="Lưu" type="primary" htmlType="submit">
          Lưu thông tin
        </Button>
      </Flex>

      <div className="building-form-container">
        <Row gutter={16}>
          <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24} span={8}>
            <Form.Item<ICRUDBlockPayload>
              label="Tên phân khu"
              name="name"
              rules={[{required: true, message: 'Trường này là bắt buộc!'}]}>
              <Input size="large" placeholder="Nhập tên phân khu" />
            </Form.Item>
            {projectId && projectId === 'null' ? (
              <Form.Item<ICRUDBlockPayload> label="Thuộc dự án" name="projectId">
                <Select
                  onDropdownVisibleChange={open => (open ? getListProjects() : null)}
                  size="large"
                  options={listProject}
                  fieldNames={{value: '_id', label: 'shortName'}}
                  placeholder="Chọn dự án"
                  value={form.getFieldValue('projectId')}
                  onChange={value => form.setFieldValue('projectId', value)}
                  loading={loadingProject}
                />
              </Form.Item>
            ) : (
              <></>
            )}
          </Col>
          <Col xxl={16} xl={16} lg={16} md={24} sm={24} xs={24} span={16}>
            <Form.Item<ICRUDBlockPayload>
              label="Mô tả phân khu"
              name="description"
              rules={[{required: true, message: 'Trường này là bắt buộc!'}]}>
              <ReactQuill
                style={{height: 350}}
                theme="snow"
                placeholder="Nhập mô tả phân khu"
                modules={DEFAULT_QUILL_MODULES}
                formats={DEFAULT_QUILL_FORMAT}
                value={form.getFieldValue('content')}
                onChange={value => form.setFieldValue('content', value)}
              />
            </Form.Item>
          </Col>
        </Row>

        <LinkAndOverviewRowForm type="block" />
      </div>
    </Form>
  );
};

export default AddBlockScreen;

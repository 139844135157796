import http, {IBaseHTTPResponse} from 'src/config/http';
import {IUpdatePasswordPayload, IUpdateAccountPayload, IUpdateUserResponse, IAccountResponse} from './type';
import {ACCOUNT_ENDPOINT} from './endpoint';

export default class AccountService {
  public async getInfo() {
    const {data} = await http.get<IAccountResponse>(ACCOUNT_ENDPOINT.common);
    return data;
  }
  public async updateAccount(payload: IUpdateAccountPayload) {
    const {data} = await http.post<IUpdateUserResponse>(ACCOUNT_ENDPOINT.common, payload);
    return data;
  }
  public async changePassword(payload: IUpdatePasswordPayload) {
    const {data} = await http.post<IBaseHTTPResponse>(ACCOUNT_ENDPOINT.updatePassword, payload);
    return data;
  }
}

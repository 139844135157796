import {CheckCircleOutlined, PlusOutlined, RightOutlined} from '@ant-design/icons';
import {Button, Card, Col, Flex, List, Popconfirm, Row, Spin, Tag, Tooltip, Typography, notification} from 'antd';
import parse from 'html-react-parser';
import {FC, Fragment, useCallback, useEffect, useState} from 'react';
import {useParams} from 'react-router';
import {useNavigate} from 'react-router-dom';
import {ROUTE_CONFIG} from 'src/routes/config';
import ScreenParams from 'src/routes/screenParams';
import BlockService from 'src/services/block';
import {IBlockInfo} from 'src/services/block/type';
import BuildingService from 'src/services/building';
import {IBuildingInfo} from 'src/services/building/type';
import {toggleReloadProject} from 'src/stores/common/layout.slice';
import {useAppDispatch} from 'src/stores/utils';
import {checkIsAdmin, checkIsSuperAdmin} from 'src/utils/common/function';
import {showNotiErr} from 'src/utils/notification';
//
const {Title} = Typography;
const blockService = new BlockService();
const buildingService = new BuildingService();
//
const BlockInfoScreen: FC = () => {
  const {id} = useParams<ScreenParams['BLOCK_INFO']>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  //
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
  const [blockDetail, setBlockDetail] = useState<IBlockInfo | null>(null);
  const [listBuilding, setListBuilding] = useState<IBuildingInfo[]>([]);
  //
  useEffect(() => {
    getBlockInfo();
    getListBuilding();
  }, [id]);
  //
  const getBlockInfo = async () => {
    if (id) {
      setLoading(true);
      await blockService
        .getBlockDetail(id)
        .then(res => {
          const {data} = res;
          setBlockDetail(data);
        })
        .catch(err => showNotiErr(err))
        .finally(() => setLoading(false));
    }
  };
  const getListBuilding = async () => {
    if (id) {
      setLoading(true);
      buildingService
        .listBuilding({
          page: 1,
          results: 50,
        })
        .then(res => {
          const {data} = res;
          const {list} = data;
          const buildingsInThisBlock = list.filter(item => item.blockId && item.blockId === id);
          setListBuilding(buildingsInThisBlock);
        })
        .catch(err => showNotiErr(err))
        .finally(() => setLoading(false));
    }
  };
  //

  const handleNavigateEditBlock = useCallback(() => {
    if (blockDetail && blockDetail._id) {
      navigate(`${ROUTE_CONFIG.UPDATE_BLOCK}/${blockDetail._id}`);
    }
  }, [blockDetail]);
  const handleDeleteBlock = useCallback(async () => {
    setLoadingDelete(true);
    blockService
      .deleteBlock(blockDetail?._id || '')
      .then(res => {
        const {message} = res;
        notification.success({message});
        navigate(ROUTE_CONFIG.HOME);
        dispatch(toggleReloadProject());
      })
      .catch(err => {
        showNotiErr(err);
      })
      .finally(() => {
        setLoadingDelete(false);
      });
  }, [blockDetail]);
  //
  const handleNavigateAddBuilding = () => {
    navigate(`${ROUTE_CONFIG.ADD_BUILDING}/${blockDetail?.projectId || 'null'}/${blockDetail?._id}`);
  };
  return (
    <Fragment>
      <Row gutter={16}>
        <Col xxl={6} xl={6} lg={6} md={6} sm={24} xs={24} span={6}>
          <Card style={{marginBottom: 16}} title="Tổng quan" styles={{body: {paddingBlockStart: 0}}}>
            <List
              size="small"
              itemLayout="horizontal"
              dataSource={blockDetail?.overviews || []}
              renderItem={(item, index) => (
                <List.Item>
                  <List.Item.Meta avatar={<CheckCircleOutlined />} title={item.title} description={item.value} />
                </List.Item>
              )}
            />
          </Card>
          <Card
            style={{marginBottom: 15}}
            title={
              <Flex justify="space-between" align="center">
                <Title level={5}>Toà nhà</Title>
                {(checkIsAdmin() || checkIsSuperAdmin()) && (
                  <Tooltip title="Thêm toà nhà" trigger="hover">
                    <Button onClick={handleNavigateAddBuilding} type="text" shape="circle" icon={<PlusOutlined />} />
                  </Tooltip>
                )}
              </Flex>
            }
            styles={{body: {paddingBlockStart: 0}}}>
            <List
              size="small"
              itemLayout="horizontal"
              dataSource={listBuilding}
              renderItem={(item, index) => (
                <List.Item onClick={() => navigate(`${ROUTE_CONFIG.BUILDING_INFO}/${item?._id}`)}>
                  <List.Item.Meta avatar={<RightOutlined />} title={item.name} />
                </List.Item>
              )}
            />
          </Card>
        </Col>

        <Col xxl={18} xl={18} lg={18} md={18} sm={24} xs={24} span={18}>
          <Card
            styles={{body: {paddingBlockStart: 0}}}
            title={
              <Flex justify="space-between" align="center" wrap="wrap">
                <Title level={3}>{blockDetail?.name}</Title>
                {checkIsSuperAdmin() && (
                  <Flex align="center" gap={5}>
                    <Button onClick={handleNavigateEditBlock} type="primary">
                      Chỉnh sửa thông tin
                    </Button>
                    <Popconfirm
                      title="Xác nhận xoá phân khu"
                      description="Bạn chắc chắn muốn xoá phân khu này?"
                      onConfirm={handleDeleteBlock}
                      okButtonProps={{loading: loadingDelete}}
                      okText="Đồng ý"
                      cancelText="Huỷ">
                      <Button type="dashed" danger>
                        Xoá phân khu
                      </Button>
                    </Popconfirm>
                  </Flex>
                )}
              </Flex>
            }>
            {blockDetail && blockDetail.links ? (
              <Flex align="center" gap={5} style={{marginBottom: 15}}>
                {blockDetail.links.map((item, index) => (
                  <Tag
                    key={index}
                    color="blue"
                    style={{cursor: 'pointer'}}
                    onClick={() => window.open(item.value, '_blank')}>
                    {item.title}
                  </Tag>
                ))}
              </Flex>
            ) : (
              <></>
            )}
            {blockDetail ? <div>{parse(blockDetail.description || '')}</div> : <></>}
          </Card>
        </Col>
      </Row>
      {loading && <Spin size="large" />}
    </Fragment>
  );
};
export default BlockInfoScreen;

import {ReloadOutlined} from '@ant-design/icons';
import {Button, Table} from 'antd';
import {FC} from 'react';
import useWindowSize from 'src/hooks/useWindowSize';
import {IBuildingInfo} from 'src/services/building/type';
import {IStockInfo} from 'src/services/stock/type';
import {getTableHorizontalSpace} from 'src/styles/utils';
import {getStockTableColumn} from 'src/utils/common/function';
interface IProps {
  dataTable: IStockInfo[];
  detail: IBuildingInfo | null;
  loading: boolean;
}
const StockTable: FC<IProps> = props => {
  const {commonTableHeight} = useWindowSize();
  const {dataTable, loading, detail} = props;
  const tableColumn = getStockTableColumn(detail);

  return (
    <div>
      <Table
        sticky
        size="small"
        loading={loading}
        dataSource={dataTable}
        bordered
        scroll={{x: getTableHorizontalSpace(), y: commonTableHeight}}
        columns={tableColumn}
      />
    </div>
  );
};
export default StockTable;
